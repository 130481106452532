import React, { useEffect, useRef } from 'react';
import avni from '../../../../assets/avni-circle.png';
import alara from '../../../../assets/alara-circle.png';
import faizan from '../../../../assets/vertical-faizan.jpeg';
import ayush from '../../../../assets/vertical-ayush.jpeg';
import gabby from '../../../../assets/vertical-gabby.jpeg';
import christian from '../../../../assets/vertical-christian.jpeg';
import eliza from '../../../../assets/vertical-eliza.jpeg';

import person1 from '../../../../assets/landingpage/person1.png';
import person2 from '../../../../assets/landingpage/person2.png';
import person3 from '../../../../assets/landingpage/person3.png';
import person4 from '../../../../assets/landingpage/person4.png';
import person5 from '../../../../assets/landingpage/person5.png';
import person6 from '../../../../assets/landingpage/person6.png';
import person7 from '../../../../assets/landingpage/person7.png';
import person8 from '../../../../assets/landingpage/person8.png';
import person9 from '../../../../assets/landingpage/person9.png';
import person10 from '../../../../assets/landingpage/person10.png';
import person11 from '../../../../assets/landingpage/person11.png';
import person12 from '../../../../assets/landingpage/person12.png';
import person13 from '../../../../assets/landingpage/person13.png';
import person14 from '../../../../assets/landingpage/person14.png';
import person15 from '../../../../assets/landingpage/person15.png';
import person16 from '../../../../assets/landingpage/person16.png';
import person17 from '../../../../assets/landingpage/person17.png';

import './photoloop.css'; // Import your CSS file

const Photoloop = () => {
    return (
        <div className='photoloop__entire-container'>
            <div class="logos">
                <div class="logos-slide">
                    <img src={person1} alt='photo' />
                    <img src={person2} alt='photo' />
                    <img src={person3} alt='photo' />
                    {/* <img src={person4} alt='photo' /> */}
                    <img src={person5} alt='photo' />
                    <img src={person6} alt='photo' />
                    <img src={person7} alt='photo' />
                    <img src={person8} alt='photo' />
                    <img src={person9} alt='photo' />
                    <img src={person10} alt='photo' />
                    <img src={person11} alt='photo' />
                    <img src={person12} alt='photo' />
                    <img src={person13} alt='photo' />
                    <img src={person14} alt='photo' />
                    <img src={person15} alt='photo' />
                    {/* <img src={person16} alt='photo' /> */}
                    <img src={person17} alt='photo' />
                </div>
                <div class="logos-slide">
                    <img src={person1} alt='photo' />
                    <img src={person2} alt='photo' />
                    <img src={person3} alt='photo' />
                    {/* <img src={person4} alt='photo' /> */}
                    <img src={person5} alt='photo' />
                    <img src={person6} alt='photo' />
                    <img src={person7} alt='photo' />
                    <img src={person8} alt='photo' />
                    <img src={person9} alt='photo' />
                    <img src={person10} alt='photo' />
                    <img src={person11} alt='photo' />
                    <img src={person12} alt='photo' />
                    <img src={person13} alt='photo' />
                    <img src={person14} alt='photo' />
                    <img src={person15} alt='photo' />
                    {/* <img src={person16} alt='photo' /> */}
                    <img src={person17} alt='photo' />
                </div>
                <div class="logos-slide">
                    <img src={person1} alt='photo' />
                    <img src={person2} alt='photo' />
                    <img src={person3} alt='photo' />
                    {/* <img src={person4} alt='photo' /> */}
                    <img src={person5} alt='photo' />
                    <img src={person6} alt='photo' />
                    <img src={person7} alt='photo' />
                    <img src={person8} alt='photo' />
                    <img src={person9} alt='photo' />
                    <img src={person10} alt='photo' />
                    <img src={person11} alt='photo' />
                    <img src={person12} alt='photo' />
                    <img src={person13} alt='photo' />
                    <img src={person14} alt='photo' />
                    <img src={person15} alt='photo' />
                    {/* <img src={person16} alt='photo' /> */}
                    <img src={person17} alt='photo' />
                </div>
                <div class="logos-slide">
                    <img src={person1} alt='photo' />
                    <img src={person2} alt='photo' />
                    <img src={person3} alt='photo' />
                    {/* <img src={person4} alt='photo' /> */}
                    <img src={person5} alt='photo' />
                    <img src={person6} alt='photo' />
                    <img src={person7} alt='photo' />
                    <img src={person8} alt='photo' />
                    <img src={person9} alt='photo' />
                    <img src={person10} alt='photo' />
                    <img src={person11} alt='photo' />
                    <img src={person12} alt='photo' />
                    <img src={person13} alt='photo' />
                    <img src={person14} alt='photo' />
                    <img src={person15} alt='photo' />
                    {/* <img src={person16} alt='photo' /> */}
                    <img src={person17} alt='photo' />
                </div>
            </div>
        </div>
    );
};

export default Photoloop;
