import React from 'react';
import trainer from '../../../assets/personal-trainer.png';
import './boxcomponent.css';

/* images are downloaded from iconscout as 1000 x 1000 png */

const Boxcomponent = ({
                        boxcomponentImage,
                        boxcomponentUniversity,
                        boxcomponentDescription,
                        boxcomponentClass,
                      }) => {
  return (
    <div className='boxcomponent__entire-container'>
      <div className='boxcomponent__image-container'>
        <img src={boxcomponentImage} alt='person' className='boxcomponent__image' />
      </div>
      <div className='boxcomponent__text-container'>
        <p className='boxcomponent__university-text'>{boxcomponentUniversity}</p>
        <p className='boxcomponent__description-text'>
          {boxcomponentDescription}
        </p>
        <p className='boxcomponent__class-text'><span style={{ fontWeight: 700 }}>class: </span>{boxcomponentClass}</p>
      </div>
    </div>
  );
};

export default Boxcomponent