import React from 'react';
import logo1 from '../../../assets/college_logos/logo-berkeley.png';
import logo2 from '../../../assets/college_logos/logo-brown.png';
import logo3 from '../../../assets/college_logos/logo-cornell.png';
import logo4 from '../../../assets/college_logos/logo-duke.png';
import logo5 from '../../../assets/college_logos/logo-georgetown.png';
import logo6 from '../../../assets/college_logos/logo-northwestern.png';
import logo7 from '../../../assets/college_logos/logo-notredame.png';
import logo8 from '../../../assets/college_logos/logo-penn.png';
import logo9 from '../../../assets/college_logos/logo-rice.png';
import logo10 from '../../../assets/college_logos/logo-stanford.png';
import logo11 from '../../../assets/college_logos/logo-umich.png';
import logo12 from '../../../assets/college_logos/logo-unc.png';
import logo13 from '../../../assets/college_logos/logo-uva.png';
import logo14 from '../../../assets/college_logos/logo-vanderbilt.png';
import logo15 from '../../../assets/college_logos/logo-wakeforest.png';
import './verticalphotoloop.css';

const Verticalphotoloop = () => {
    return (
        <div className='verticalphotoloop__entire-container'>
            <div class="verticalphotoloop__logos">
                <div class="verticalphotoloop__logos-slide">
                    {/* <img src={logo1} alt='photo' />
                    <img src={logo2} alt='photo' />
                    <img src={logo3} alt='photo' />
                    <img src={logo4} alt='photo' />
                    <img src={logo5} alt='photo' />
                    <img src={logo6} alt='photo' />
                    <img src={logo7} alt='photo' />
                    <img src={logo8} alt='photo' />
                    <img src={logo9} alt='photo' />
                    <div style={{ transform: "scale(.4)" }}><img src={logo10} alt='photo' /></div>
                    <img src={logo11} alt='photo' />
                    <img src={logo12} alt='photo' />
                    <img src={logo13} alt='photo' />
                    <img src={logo14} alt='photo' />
                    <img src={logo15} alt='photo' /> */}
                    <p>university of california, berkeley</p>
                    <p>vanderbilt university</p>
                    <p>stanford university</p>
                    <p>duke university</p>
                    <p>georgetown university</p>
                    <p>northwestern university</p>
                    <p>the university of pennsylvania</p>
                    <p>the university of michigan</p>
                    <p>notre dame university</p>
                    <p>rice university</p>
                    <p>brown university</p>
                    <p>the university of north carolina</p>
                    <p>cornell university</p>
                    <p>the university of virgina</p>
                    <p>wake forest university</p>
                </div>
                <div class="verticalphotoloop__logos-slide">
                    <p>university of california, berkeley</p>
                    <p>vanderbilt university</p>
                    <p>stanford university</p>
                    <p>duke university</p>
                    <p>georgetown university</p>
                    <p>northwestern university</p>
                    <p>the university of pennsylvania</p>
                    <p>the university of michigan</p>
                    <p>notre dame university</p>
                    <p>rice university</p>
                    <p>brown university</p>
                    <p>the university of north carolina</p>
                    <p>cornell university</p>
                    <p>the university of virgina</p>
                    <p>wake forest university</p>
                </div>
                <div class="verticalphotoloop__logos-slide">
                    <p>university of california, berkeley</p>
                    <p>vanderbilt university</p>
                    <p>stanford university</p>
                    <p>duke university</p>
                    <p>georgetown university</p>
                    <p>northwestern university</p>
                    <p>the university of pennsylvania</p>
                    <p>the university of michigan</p>
                    <p>notre dame university</p>
                    <p>rice university</p>
                    <p>brown university</p>
                    <p>the university of north carolina</p>
                    <p>cornell university</p>
                    <p>the university of virgina</p>
                    <p>wake forest university</p>
                </div>
                <div class="verticalphotoloop__logos-slide">
                    <p>university of california, berkeley</p>
                    <p>vanderbilt university</p>
                    <p>stanford university</p>
                    <p>duke university</p>
                    <p>georgetown university</p>
                    <p>northwestern university</p>
                    <p>the university of pennsylvania</p>
                    <p>the university of michigan</p>
                    <p>notre dame university</p>
                    <p>rice university</p>
                    <p>brown university</p>
                    <p>the university of north carolina</p>
                    <p>cornell university</p>
                    <p>the university of virgina</p>
                    <p>wake forest university</p>
                </div>
            </div>
        </div>
    );
}

export default Verticalphotoloop