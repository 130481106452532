// import React from "react";
// import './about_page.css';
// import ayushneel from '../../assets/ayushneel.JPG';

// function handleScheduleButtonClick() {
//     window.open('https://calendly.com/neelsn/chat-with-founder', '_blank');
// }

// const About_Page = () => {
//     return (
//         <div className="about_page__entire-container">
//             <div className="about_page__top-area-container">
//                 {/* <h1>at ember, we're leveling the playing field.</h1> */}
//             </div>
//             <div className="about_page__middle-area-container">
//                 <div className="about_page__image-container">
//                     <p style=
//                         {{ 
//                             fontWeight: 'bold',
//                             color: 'var(--color-orange)',
//                             fontSize: 'clamp(12px, 3vw, 50px)'
//                         }}
//                     >
//                         about us
//                     </p>
//                     <img src={ayushneel} />
//                 </div>
//                 <div className="about_page__paragraph">
//                     <p>
//                         welcome to <span style={{ color: '#F4AE02' }}>ember</span>, the first-of-its-kind platform that connects high school students with current university undergraduates.
//                     </p>
//                     <br />
//                     <p>
//                         we are { }
//                         <a style={{ color: '#DFEDEC', cursor: 'pointer', textDecoration: 'underline' }} href="https://www.linkedin.com/in/neelsn/" target="_blank" rel="noopener noreferrer">neel narayan</a> and { }
//                         <a style={{ color: '#DFEDEC', cursor: 'pointer', textDecoration: 'underline' }} href="https://www.linkedin.com/in/ayushkrishnamoorti/" target="_blank" rel="noopener noreferrer">ayush krishnamoorti</a>
//                         , founders of ember and current stanford university students.
//                         back in june, we read an article about the many inequalities in the admissions process.
//                         what we found was that for lower income students, it was very difficult to get admissions advice, take campus tours, and understand the intracacies of a college application.
//                         these issues were further exacerbated by the fact that in a market saturated with expensive college counselors, it was very difficult to find affordable advice and guidance.
//                         even though these concerns weren't novel, they were relevant.
//                     </p>
//                     <br />
//                     <p>
//                         as such, we set out to try to level the playing field. a few months later in san francisco, <span style={{ color: '#F4AE02' }}>ember</span> was born.
//                     </p>
//                     <br />
//                     <p>
//                         ayush and i decided that the most insightful and authentic perspective of a university can only come from its current students.
//                         we began urgining our friends to share their stories and give unfiltered advice to students that needed it the most.
//                         they joined ember, and now, they're ready to help you.
//                     </p>
//                     <br />
//                     <p>
//                         by facilitating our <span style={{ color: '#F4AE02' }}>ember</span> experiences - virtual chats, in-person chats, and personalized campus tours - we hope to help students gauge whether or not the university is a fit for them and give them more transparency into schools during the admissions process.
//                     </p>
//                     <br />
//                     <p>
//                         using a proprietary matching algorithm developed by our team at stanford, we connect high school students with undergraduates based on personality matches and similar interests. we want you to experience the school through the eyes of someone who is just like you, and at ember, that's exactly what you'll get.
//                     </p>
//                     <br />
//                     <p>
//                         we set out to create a platform accessible to everyone.
//                         after numerous conversations with parents and university students, we crafted our pricing model to accomodate our ember guides fairly while ensuring that everyone could use our service.
//                         and because we care so much about authenticity, we openly disclose our margins and how much our guides make.
//                     </p>
//                     <br />
//                     <p>
//                         thank you for trusting us during such a pivotal moment in your life.
//                         the admissions process is scary and it's trying, and while we may not be able to alleviate all of your problems, we hope to give you more power and confidence while navigating this passage.
//                     </p>
//                     <br />
//                     <p>
//                         so with open arms, we welcome you to <span style={{ color: '#F4AE02' }}>ember</span>. we're ready for you.
//                     </p>
//                     <br />
//                     <p>
//                         <a href="/" style={{ color: '#DFEDEC', cursor: 'pointer', textDecoration: 'underline' }}>return home.</a>
//                     </p>
//                 </div>
//             </div>
//             {/* <div className="about_page-bottom-area-container">
//                 <p>we care about your feedback. any questions? book time with us.</p>
//                 <p>schedule a 30 minute time slot with us. we'd be more than happy to talk :)</p>
//                 <button onClick={handleScheduleButtonClick}>schedule</button>
//             </div> */}
//         </div>
//     )
// };

// export default About_Page;










import React from "react";
import './about_page.css';
import neel from '../../assets/neelabout.jpeg';

function handleScheduleButtonClick() {
    window.open('https://calendly.com/neelsn/chat-with-founder', '_blank');
}

const About_Page = () => {
    return (
        <div className="about_page__entire-container">
            <div className="about_page__top-area-container">
                {/* <h1>at ember, we're leveling the playing field.</h1> */}
            </div>
            <div className="about_page__middle-area-container">
                <div className="about_page__image-container">
                    <p style=
                        {{ 
                            fontWeight: 'bold',
                            color: 'var(--color-orange)',
                            fontSize: 'clamp(12px, 3vw, 50px)'
                        }}
                    >
                        about ember
                    </p>
                    <img src={neel} />
                </div>
                <div className="about_page__paragraph">
                    <p>
                        welcome to <span style={{ color: '#F4AE02' }}>ember</span>, the first-of-its-kind platform that connects high school students with current university undergraduates.
                    </p>
                    <br />
                    <p>
                        my name is { }
                        <a style={{ color: '#DFEDEC', cursor: 'pointer', textDecoration: 'underline' }} href="https://www.linkedin.com/in/neelsn/" target="_blank" rel="noopener noreferrer">neel narayan</a> 
                        , the founder of ember and a current stanford university student.
                        back in june, i read an article about the many inequalities in the admissions process.
                        what i found was that for lower income students, it was very difficult to get admissions advice, take campus tours, and understand the intracacies of a college application.
                        these issues were further exacerbated by the fact that in a market saturated with expensive college counselors, it was very difficult to find affordable advice and guidance.
                        even though these concerns weren't novel, they were relevant.
                    </p>
                    <br />
                    <p>
                        as such, i set out to try to level the playing field. a few months later in san francisco, <span style={{ color: '#F4AE02' }}>ember</span> was born.
                    </p>
                    <br />
                    <p>
                        i decided that the most insightful and authentic perspective of a university can only come from its current students.
                        as such, i began urgining my friends to share their stories and give unfiltered advice to students that needed it the most.
                        they joined ember, and now, they're ready to help you.
                    </p>
                    <br />
                    <p>
                        by facilitating <span style={{ color: '#F4AE02' }}>ember</span> experiences - virtual chats, in-person chats, and personalized campus tours - i hope to help students gauge whether or not the university is a fit for them and give them more transparency into schools during the admissions process.
                    </p>
                    <br />
                    <p>
                        using a proprietary matching algorithm developed at stanford, we connect high school students with undergraduates based on personality matches and similar interests. we want you to experience the school through the eyes of someone who is just like you, and at <span style={{ color: '#F4AE02' }}>ember</span>, that's exactly what you'll get.
                    </p>
                    <br />
                    <p>
                        i set out to create a platform accessible to everyone.
                        after numerous conversations with parents and university students, i crafted the pricing model to accomodate <span style={{ color: '#F4AE02' }}>ember</span> guides fairly while ensuring that everyone could use the platform.
                        and because authenticity is so important, we openly disclose our margins and how much our guides make.
                    </p>
                    <br />
                    <p>
                        thank you for trusting <span style={{ color: '#F4AE02' }}>ember</span> during such a pivotal moment in your life.
                        the admissions process is scary and it's trying, and while <span style={{ color: '#F4AE02' }}>ember</span> may not be able to alleviate all of your problems, we hope to give you more power and confidence while navigating this passage.
                    </p>
                    <br />
                    <p>
                        so with open arms, i welcome you to <span style={{ color: '#F4AE02' }}>ember</span>. we're ready for you.
                    </p>
                    <br />
                    <p>
                        <a href="/" style={{ color: '#DFEDEC', cursor: 'pointer', textDecoration: 'underline' }}>return home.</a>
                    </p>
                </div>
            </div>
            {/* <div className="about_page-bottom-area-container">
                <p>we care about your feedback. any questions? book time with us.</p>
                <p>schedule a 30 minute time slot with us. we'd be more than happy to talk :)</p>
                <button onClick={handleScheduleButtonClick}>schedule</button>
            </div> */}
        </div>
    )
};

export default About_Page;
