import React from 'react';

import step1 from '../../assets/one-number.svg';
import step2 from '../../assets/two-number.svg';
import step3 from '../../assets/three-number.svg';
import step4 from '../../assets/four-number.svg';

import './mostnewsteps.css';

const Mostnewsteps = () => {
    return (
        <div className='mostnewsteps__entire-container'>
            <div className='mostnewsteps__right-side-container'>
                {/* <h1>all it takes is 4 steps to get your college student</h1> */}
                <h1>all it takes is 4 steps to begin your experience</h1>
                <div className='mostnewsteps__step-entire-container'>
                    <div className='mostnewsteps__step-left-side-container'>
                        <img src={step1} alt='number' />
                        <div className='mostnewsteps__step-left-line'></div>
                    </div>
                    <div className='mostnewsteps__step-right-side-container'>
                        <h2>onboard</h2>
                        <p className='mostnewsteps__desktop-content'>in less than a minute, you can join ember and begin your search for a personalized guide</p>
                        <p className='mostnewsteps__desktop-content'>search through our list of schools where ember is live</p>
                        <p className='mostnewsteps__mobile-content'>join us in less than a minute</p>
                    </div>
                </div>
                <div className='mostnewsteps__step-entire-container'>
                    <div className='mostnewsteps__step-left-side-container'>
                        <img src={step2} alt='number' />
                        <div className='mostnewsteps__step-left-line'></div>
                    </div>
                    <div className='mostnewsteps__step-right-side-container'>
                        <h2>find your guide</h2>
                        <p className='mostnewsteps__desktop-content'>start looking for students that fit your preferences in our database of 100+ guides</p>
                        <p className='mostnewsteps__desktop-content'>refine your search by filtering on major, hometown, and interests</p>
                        <p className='mostnewsteps__mobile-content'>give us your preferences</p>
                    </div>
                </div>
                <div className='mostnewsteps__step-entire-container'>
                    <div className='mostnewsteps__step-left-side-container'>
                        <img src={step3} alt='number' />
                        <div className='mostnewsteps__step-left-line'></div>
                    </div>
                    <div className='mostnewsteps__step-right-side-container'>
                        <h2>relax</h2>
                        <p className='mostnewsteps__desktop-content'>once you've found the student best tailored to your needs, we'll handle the rest</p>
                        <p className='mostnewsteps__desktop-content'>you'll then recieve an email with all of the details you need</p>
                        <p className='mostnewsteps__mobile-content'>we'll do the matching for you</p>
                    </div>
                </div>
                <div className='mostnewsteps__step-entire-container'>
                    <div className='mostnewsteps__step-left-side-container'>
                        <img src={step4} alt='number' />
                        <div className='mostnewsteps__step-left-line'></div>
                    </div>
                    <div className='mostnewsteps__step-right-side-container'>
                        <h2>experience!</h2>
                        <p className='mostnewsteps__desktop-content'>meet up with your student guide and begin your personalized ember experience</p>
                        <p className='mostnewsteps__desktop-content'>experience the campus as a student and then book the next one!</p>
                        <p className='mostnewsteps__mobile-content'>feel campus as a real student</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mostnewsteps