import React from 'react';
import Profile_Display_Page from '../../../profile_display_page/Profile_Display_Page';
import './modal.css';

const Modal = ({
                closeModal,
                profilePhoto,
                firstName,
                lastName,
                major,
                classYear,
                gender,
                linkedin,
                city,
                state,
                highSchool,
                campusQuestion,
                classQuestion,
                exciteQuestion,
                clubsQuestion,
                interestsQuestion,
                messageQuestion,
              }) => {
    return (
      <div className='modal__backdrop'>
        <div className='modal__profile-display-page'>
          <Profile_Display_Page
            profileUniversity={"stanford university"}
            profilePhoto={profilePhoto}
            profileFirstName={firstName}
            profileLastName={lastName}
            profileMajor={major}
            profileClass={classYear}
            profileGender={gender}
            profileLinkedin={linkedin}
            profileCity={city}
            profileState={state}
            profileHighSchool={highSchool}
            profileCampusQuestion={campusQuestion}
            profileClassQuestion={classQuestion}
            profileExciteQuestion={exciteQuestion}
            profileClubsQuestion={clubsQuestion}
            profileInterestsQuestion={interestsQuestion}
            profileMessageQuestion={messageQuestion}
            profileEndButton1OnclickAction={closeModal}
            profileEndButton1Label={"close"}
            profileEndButton1Visibility={"show"}
            profileEndButton2Visibility={"hidden"}
            profileEndButton3Visibility={"hidden"}
          />
        </div>
      </div>
    )
}

export default Modal