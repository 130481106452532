// export const searchMajorOptions = [
//     { name: 'select major', value: 'searchSelectMajor' },
//     { name: 'aeronautics and astronautics', value: 'searchAeronauticsAndAstronautics' },
//     { name: 'bioengineering', value: 'searchBioengineering' },
//     { name: 'biology', value: 'searchBiology' },
//     { name: 'chemical engineering', value: 'searchChemicalEngineering' },
//     { name: 'chemistry', value: 'searchChemistry' },
//     { name: 'civil engineering', value: 'searchCivilEngineering' },
//     { name: 'computer science', value: 'searchComputerScience' },
//     { name: 'data science', value: 'searchDataScience' },
//     { name: 'design', value: 'searchDesign' },
//     { name: 'earth systems', value: 'searchEarthSystems' },
//     { name: 'electrical engineering', value: 'searchElectricalEngineering' },
//     { name: 'energy resources engineering', value: 'searchEnergyResourcesEngineering' },
//     { name: 'engineering', value: 'searchEngineering' },
//     { name: 'environmental systems engineering', value: 'searchEnvironmentalSystemsEngineering' },
//     { name: 'geological sciences', value: 'searchGeologicalSciences' },
//     { name: 'geophysics', value: 'searchGeophysics' },
//     { name: 'human biology', value: 'searchHumanBiology' },
//     { name: 'individually designed major in engineering', value: 'searchIndividuallyDesignedMajorInEngineering' },
//     { name: 'management science and engineering', value: 'searchManagementScienceAndEngineering' },
//     { name: 'materials science and engineering', value: 'searchMaterialsScienceAndEngineering' },
//     { name: 'mathematical and computational science', value: 'searchMathematicalAndComputationalScience' },
//     { name: 'mathematics', value: 'searchMathematics' },
//     { name: 'mechanical engineering', value: 'searchMechanicalEngineering' },
//     { name: 'physics', value: 'searchPhysics' },
//     { name: 'science, technology, and society', value: 'searchScienceTechnologyAndSociety' },
//     { name: 'symbolic systems', value: 'searchSymbolicSystems' },
//     { name: 'african and african american studies', value: 'searchAfricanAndAfricanAmericanStudies' },
//     { name: 'american studies', value: 'searchAmericanStudies' },
//     { name: 'anthropology', value: 'searchAnthropology' },
//     { name: 'archaeology', value: 'searchArchaeology' },
//     { name: 'art history', value: 'searchArtHistory' },
//     { name: 'art practice', value: 'searchArtPractice' },
//     { name: 'asian american studies', value: 'searchAsianAmericanStudies' },
//     { name: 'chicana/o - latina/o studies', value: 'searchChicanaLatinaStudies' },
//     { name: 'classics', value: 'searchClassics' },
//     { name: 'communication', value: 'searchCommunication' },
//     { name: 'comparative literature', value: 'searchComparativeLiterature' },
//     { name: 'comparative studies in race and ethnicity', value: 'searchComparativeStudiesInRaceAndEthnicity' },
//     { name: 'east asian studies', value: 'searchEastAsianStudies' },
//     { name: 'economics', value: 'searchEconomics' },
//     { name: 'english', value: 'searchEnglish' },
//     { name: 'feminist, gender, and sexuality studies', value: 'searchFeministGenderAndSexualityStudies' },
//     { name: 'film and media studies', value: 'searchFilmAndMediaStudies' },
//     { name: 'french', value: 'searchFrench' },
//     { name: 'german studies', value: 'searchGermanStudies' },
//     { name: 'history', value: 'searchHistory' },
//     { name: 'iberian and latin american cultures', value: 'searchIberianAndLatinAmericanCultures' },
//     { name: 'international relations', value: 'searchInternationalRelations' },
//     { name: 'italian', value: 'searchItalian' },
//     { name: 'jewish studies', value: 'searchJewishStudies' },
//     { name: 'linguistics', value: 'searchLinguistics' },
//     { name: 'music', value: 'searchMusic' },
//     { name: 'native american studies', value: 'searchNativeAmericanStudies' },
//     { name: 'philosophy', value: 'searchPhilosophy' },
//     { name: 'philosophy and religious studies', value: 'searchPhilosophyAndReligiousStudies' },
//     { name: 'political science', value: 'searchPoliticalScience' },
//     { name: 'psychology', value: 'searchPsychology' },
//     { name: 'public policy', value: 'searchPublicPolicy' },
//     { name: 'religious studies', value: 'searchReligiousStudies' },
//     { name: 'slavic languages and literatures', value: 'searchSlavicLanguagesAndLiteratures' },
//     { name: 'sociology', value: 'searchSociology' },
//     { name: 'spanish', value: 'searchSpanish' },
//     { name: 'theater and performance studies', value: 'searchTheaterAndPerformanceStudies' },
//     { name: 'urban studies', value: 'searchUrbanStudies' },
// ];

export const searchMajorOptions = [
    { name: 'select major', value: 'searchSelectMajor' },
    { name: 'aeronautics and astronautics', value: 'searchAeronauticsAndAstronautics' },
    { name: 'african and african american studies', value: 'searchAfricanAndAfricanAmericanStudies' },
    { name: 'american studies', value: 'searchAmericanStudies' },
    { name: 'anthropology', value: 'searchAnthropology' },
    { name: 'archaeology', value: 'searchArchaeology' },
    { name: 'art history', value: 'searchArtHistory' },
    { name: 'art practice', value: 'searchArtPractice' },
    { name: 'asian american studies', value: 'searchAsianAmericanStudies' },
    { name: 'bioengineering', value: 'searchBioengineering' },
    { name: 'biology', value: 'searchBiology' },
    { name: 'chemical engineering', value: 'searchChemicalEngineering' },
    { name: 'chemistry', value: 'searchChemistry' },
    { name: 'chicana/o - latina/o studies', value: 'searchChicanaLatinaStudies' },
    { name: 'civil engineering', value: 'searchCivilEngineering' },
    { name: 'classics', value: 'searchClassics' },
    { name: 'communication', value: 'searchCommunication' },
    { name: 'comparative literature', value: 'searchComparativeLiterature' },
    { name: 'comparative studies in race and ethnicity', value: 'searchComparativeStudiesInRaceAndEthnicity' },
    { name: 'computer science', value: 'searchComputerScience' },
    { name: 'data science', value: 'searchDataScience' },
    { name: 'design', value: 'searchDesign' },
    { name: 'earth systems', value: 'searchEarthSystems' },
    { name: 'east asian studies', value: 'searchEastAsianStudies' },
    { name: 'economics', value: 'searchEconomics' },
    { name: 'electrical engineering', value: 'searchElectricalEngineering' },
    { name: 'energy resources engineering', value: 'searchEnergyResourcesEngineering' },
    { name: 'engineering', value: 'searchEngineering' },
    { name: 'english', value: 'searchEnglish' },
    { name: 'environmental systems engineering', value: 'searchEnvironmentalSystemsEngineering' },
    { name: 'feminist, gender, and sexuality studies', value: 'searchFeministGenderAndSexualityStudies' },
    { name: 'film and media studies', value: 'searchFilmAndMediaStudies' },
    { name: 'french', value: 'searchFrench' },
    { name: 'geological sciences', value: 'searchGeologicalSciences' },
    { name: 'geophysics', value: 'searchGeophysics' },
    { name: 'german studies', value: 'searchGermanStudies' },
    { name: 'history', value: 'searchHistory' },
    { name: 'human biology', value: 'searchHumanBiology' },
    { name: 'iberian and latin american cultures', value: 'searchIberianAndLatinAmericanCultures' },
    { name: 'individually designed major in engineering', value: 'searchIndividuallyDesignedMajorInEngineering' },
    { name: 'international relations', value: 'searchInternationalRelations' },
    { name: 'italian', value: 'searchItalian' },
    { name: 'jewish studies', value: 'searchJewishStudies' },
    { name: 'linguistics', value: 'searchLinguistics' },
    { name: 'management science and engineering', value: 'searchManagementScienceAndEngineering' },
    { name: 'materials science and engineering', value: 'searchMaterialsScienceAndEngineering' },
    { name: 'mathematical and computational science', value: 'searchMathematicalAndComputationalScience' },
    { name: 'mathematics', value: 'searchMathematics' },
    { name: 'mechanical engineering', value: 'searchMechanicalEngineering' },
    { name: 'music', value: 'searchMusic' },
    { name: 'native american studies', value: 'searchNativeAmericanStudies' },
    { name: 'philosophy and religious studies', value: 'searchPhilosophyAndReligiousStudies' },
    { name: 'philosophy', value: 'searchPhilosophy' },
    { name: 'physics', value: 'searchPhysics' },
    { name: 'political science', value: 'searchPoliticalScience' },
    { name: 'psychology', value: 'searchPsychology' },
    { name: 'public policy', value: 'searchPublicPolicy' },
    { name: 'religious studies', value: 'searchReligiousStudies' },
    { name: 'science, technology, and society', value: 'searchScienceTechnologyAndSociety' },
    { name: 'slavic languages and literatures', value: 'searchSlavicLanguagesAndLiteratures' },
    { name: 'sociology', value: 'searchSociology' },
    { name: 'spanish', value: 'searchSpanish' },
    { name: 'symbolic systems', value: 'searchSymbolicSystems' },
    { name: 'theater and performance studies', value: 'searchTheaterAndPerformanceStudies' },
    { name: 'urban studies', value: 'searchUrbanStudies' },
];


export const searchLocationOptions = [
    { name: 'alabama', value: 'searchAlabama' },
    { name: 'alaska', value: 'searchAlaska' },
    { name: 'arizona', value: 'searchArizona' },
    { name: 'arkansas', value: 'searchArkansas' },
    { name: 'california', value: 'searchCalifornia' },
    { name: 'colorado', value: 'searchColorado' },
    { name: 'connecticut', value: 'searchConnecticut' },
    { name: 'delaware', value: 'searchDelaware' },
    { name: 'florida', value: 'searchFlorida' },
    { name: 'georgia', value: 'searchGeorgia' },
    { name: 'hawaii', value: 'searchHawaii' },
    { name: 'idaho', value: 'searchIdaho' },
    { name: 'illinois', value: 'searchIllinois' },
    { name: 'indiana', value: 'searchIndiana' },
    { name: 'iowa', value: 'searchIowa' },
    { name: 'kansas', value: 'searchKansas' },
    { name: 'kentucky', value: 'searchKentucky' },
    { name: 'louisiana', value: 'searchLouisiana' },
    { name: 'maine', value: 'searchMaine' },
    { name: 'maryland', value: 'searchMaryland' },
    { name: 'massachusetts', value: 'searchMassachusetts' },
    { name: 'michigan', value: 'searchMichigan' },
    { name: 'minnesota', value: 'searchMinnesota' },
    { name: 'mississippi', value: 'searchMississippi' },
    { name: 'missouri', value: 'searchMissouri' },
    { name: 'montana', value: 'searchMontana' },
    { name: 'nebraska', value: 'searchNebraska' },
    { name: 'nevada', value: 'searchNevada' },
    { name: 'new hampshire', value: 'searchNewHampshire' },
    { name: 'new jersey', value: 'searchNewJersey' },
    { name: 'new mexico', value: 'searchNewMexico' },
    { name: 'new york', value: 'searchNewYork' },
    { name: 'north carolina', value: 'searchNorthCarolina' },
    { name: 'north dakota', value: 'searchNorthDakota' },
    { name: 'ohio', value: 'searchOhio' },
    { name: 'oklahoma', value: 'searchOklahoma' },
    { name: 'oregon', value: 'searchOregon' },
    { name: 'pennsylvania', value: 'searchPennsylvania' },
    { name: 'rhode island', value: 'searchRhodeIsland' },
    { name: 'south carolina', value: 'searchSouthCarolina' },
    { name: 'south dakota', value: 'searchSouthDakota' },
    { name: 'tennessee', value: 'searchTennessee' },
    { name: 'texas', value: 'searchTexas' },
    { name: 'utah', value: 'searchUtah' },
    { name: 'vermont', value: 'searchVermont' },
    { name: 'virginia', value: 'searchVirginia' },
    { name: 'washington', value: 'searchWashington' },
    { name: 'west virginia', value: 'searchWestVirginia' },
    { name: 'wisconsin', value: 'searchWisconsin' },
    { name: 'wyoming', value: 'searchWyoming' },
];

export const searchTagOptions = [
    { name: 'sports 🏈', value: 'searchTagSports'},
    { name: 'music 🎵', value: 'searchTagMusic'},
    { name: 'outdoors 🏔️', value: 'searchTagOutdoors'},
    { name: 'foodie 🥐', value: 'searchTagFoodie'},
    { name: 'stem 🧪', value: 'searchTagStem'},
    { name: 'fli 📚', value: 'searchTagFli'},
    { name: 'sustainability 🌴', value: 'searchTagSustainability'},
    { name: 'entrepreneurship 💼', value: 'searchTagEntrepreneurship'},
    { name: 'lgbtqia 🏳️‍🌈', value: 'searchTagLgbtqia'},
    { name: 'history 🕰️', value: 'searchTagHistory'},
    { name: 'fashion 👛', value: 'searchTagFashion'},
    { name: 'arts 🎭', value: 'searchTagArts'},
    { name: 'international student ✈️', value: 'searchTagInternational'},
    { name: 'medicine 💊', value: 'searchTagMedicine'},
    { name: 'tech 💻', value: 'searchTagTech'},
    { name: 'politics 🏛️', value: 'searchTagPolitics'},
];

export const tagColors = {
    '🏈': 'rgba(167, 86, 50, 0.40)',
    '🎵': 'rgba(44, 34, 34, 0.20)',
    '🏔️': 'rgba(79, 143, 56, 0.40)',
    '🥐': 'rgba(240, 180, 39, 0.40)',
    '🧪': 'rgba(44, 206, 51, 0.40)',
    '📚': 'rgba(61, 87, 183, 0.50)',
    '🌴': 'rgba(151, 227, 56, 0.50)',
    '💼': 'rgba(183, 151, 123, 0.40)',
    '🏳️‍🌈': 'rgba(242, 220, 21, 0.40)',
    '🕰️': 'rgba(183, 151, 123, 0.40)',
    '👛': 'rgba(249, 168, 202, 0.40)',
    '🎭': 'rgba(115, 217, 255, 0.40)',
    '✈️': 'rgba(185, 185, 185, 0.40)',
    '💊': 'rgba(251, 237, 88, 0.80)',
    '💻': 'rgba(188, 188, 188, 0.40)',
    '🏛️': 'rgb(223, 196, 99, 0.40)'
};

export const tagDictionary = {
    'sports': '🏈',
    'music': '🎵',
    'outdoors': '🏔️',
    'foodie': '🥐',
    'stem': '🧪',
    'fli': '📚',
    'sustainability': '🌴',
    'entrepreneurship': '💼',
    'lgbtqia': '🏳️‍🌈',
    'history': '🕰️',
    'fashion': '👛',
    'arts': '🎭',
    'international': '✈️',
    'medicine': '💊',
    'tech': '💻',
    'politics': '🏛️',
};