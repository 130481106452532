import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { TimePicker } from 'antd';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Dnd_Container from './components/dnd_container/Dnd_Container';
import Modal from '../search_page/components/modal/Modal';
import paynow from '../../assets/paynow.png';
import './confirmation_page.css';

import b1 from '../../assets/search_pfps/b/b1.svg';
import g1 from '../../assets/search_pfps/g/g1.svg';
import nb1 from '../../assets/search_pfps/nb/nb1.svg';

import axios from 'axios';

import { useConfirmation } from '../../contexts/ConfirmationContext';
import { Squareprofilecard } from '../newsearch_page/components';
import { searchTagOptions, searchMajorOptions } from '../../info/searchDictionaries';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import TextArea from 'antd/es/input/TextArea';



const ScrollDownArrow = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const onScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) {
                setVisible(false);
            } else {
                setVisible(true);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return visible ? (
        <svg id="scroll-down-arrow" width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4V20M12 20L4 12M12 20L20 12" stroke="var(--color-orange)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    ) : null;
};

const Confirmation_Page = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    try {
        const users = location.state && location.state.users;
    } catch (error) {
        window.location.href = '/newsearch';
    }

    const users = location.state && location.state.users;

    if (!users || users.length !== 6) {
        window.location.href = '/newsearch';
    }

    const [confirmationFormData, setConfirmationFormData] = useState({
        currentUserEmail: '',
        userRanking1: JSON.stringify(users[0]),
        userRanking2: JSON.stringify(users[1]),
        userRanking3: JSON.stringify(users[2]),
        userRanking4: JSON.stringify(users[3]),
        userRanking5: JSON.stringify(users[4]),
        userRanking6: JSON.stringify(users[5]),
        userName: '',
        userAge: '',
        userPhoneNumber: '',
        userParentAttendance: false,
        userTourMedium: '',
        userPartySize: '',
        userTourStartDate: '',
        userTourEndDate: '',
        userTourStartTime: '',
        userTourEndTime: '',
        userExtraInfoMessage: '',
        userRerankMessage: '',
    });

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setConfirmationFormData(prevState => ({
                    ...prevState,
                    currentUserEmail: user.email
                }));
            } else {
                // no user is signed in currently
                window.location.href = '/newsignin';
            }
        });
        return () => unsubscribe();
    }, []);

    const [showUserModal, setShowUserModal] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const openUserModal = (user) => {
        setShowUserModal(user);
        setOpenModal(true);
    };

    const [startTime, setStartTime] = useState(false);
    const [endTime, setEndTime] = useState(false);
    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
    const [startTimeError, setStartTimeError] = useState("start time cannot be empty!");
    const [endTimeError, setEndTimeError] = useState("end time cannot be empty!");
    const handleStartTimeChange = (time) => {
        setStartTime(time);
        setIsStartTimePickerOpen(false);
        setStartTimeError("");
    
        setConfirmationFormData(prevData => ({
            ...prevData,
            userTourStartTime: time ? time.format("h:mm a") : ''
        }));
    };
    const handleEndTimeChange = (time) => {
        setEndTime(time);
        setIsEndTimePickerOpen(false);
        setEndTimeError("");
    
        setConfirmationFormData(prevData => ({
            ...prevData,
            userTourEndTime: time ? time.format("h:mm a") : ''
        }));
    };


    const [date, setDate] = useState(new Date());
    const [dateRangeError, setDateRangeError] = useState("date range cannot be empty!");
    const [rangeString, setRangeString] = useState("");
    const handleDateChange = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Set the time to 00:00:00.000 so we only compare the date
    
        const startDate = date[0];
        const endDate = date[1];
    
        if (startDate < today || endDate < today) {
            setDateRangeError("dates cannot be in the past!");
            return;
        }
    
        const date1 = startDate.toLocaleDateString();
        const date2 = endDate.toLocaleDateString();
        setDateRangeError("");
        setDate(date);
        setRangeString(date1 + " - " + date2);
    
        setConfirmationFormData(prevData => ({
            ...prevData,
            userTourStartDate: date1,
            userTourEndDate: date2
        }));
    };
    

    const getUserKey = (user) => {
        return user.firstName + user.lastName + user.major + user.classYear + user.hometownCity + user.hometownState;
    };

    function getDisplayNameFromValue(value) {
        const tagOption = searchTagOptions.find(option => option.value === value);
        if (tagOption) {
            return tagOption.name.split(" ")[0];
        }
        return null;
    };

    const pfpImages = {
        b: [b1],
        g: [g1],
        nb: [nb1],
    };

    function getRandomImage(folder) {
        const randomIndex = Math.floor(Math.random() * pfpImages[folder].length);
        return pfpImages[folder][randomIndex];
    };

    function getPronounFolder(pronouns) {
        if (pronouns === "he/him") {
            return 'b';
        } else if (pronouns === "she/her") {
            return 'g';
        } else {
            return 'nb';
        }
    };

    const [nameError, setNameError] = useState("name cannot be empty!");
    const handleNameChange = (e) => {
        const nameValue = e.target.value;
        const nameRegex = /^[A-Za-z\s]+$/;
    
        if (nameValue.trim() === "") {
            setNameError("name cannot be empty!");
            setConfirmationFormData({ ...confirmationFormData, userName: nameValue });
        } else if (nameRegex.test(nameValue)) {
            setNameError("");
            setConfirmationFormData({ ...confirmationFormData, userName: nameValue });
        } else {
            setNameError("name can only contain letters and spaces!");
        }
    };

    const [ageError, setAgeError] = useState("age cannot be empty!");
    const handleAgeChange = (e) => {
        const ageValue = e.target.value;
        const ageRegex = /^[0-9]+$/;
    
        if (ageValue.trim() === "") {
            setAgeError("age cannot be empty!");
            setConfirmationFormData({ ...confirmationFormData, userAge: ageValue });
        } else if (ageRegex.test(ageValue) && parseInt(ageValue, 10) > 0) { 
            setAgeError("");
            setConfirmationFormData({ ...confirmationFormData, userAge: ageValue });
        } else if (!ageRegex.test(ageValue)) {
            setAgeError("age can only contain numbers!");
        } else {
            setAgeError("age must be greater than 0!");
        }
    };

    const [phoneNumberError, setPhoneNumberError] = useState("phone number cannot be empty!");
    const preprocessPhoneNumber = (input) => {
        return input.replace(/\D/g, '');
    };
    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;

        const rawInputValidationRegex = /^[0-9-\s()]+$/;

        if (!rawInputValidationRegex.test(inputValue)) {
            setPhoneNumberError("phone number can only contain digits, hyphens, spaces, and parentheses!");
            return;
        }

        setConfirmationFormData({ 
            ...confirmationFormData, 
            userPhoneNumber: inputValue
        });

        const processedValue = preprocessPhoneNumber(inputValue);
        const phoneRegex = /^(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4})$/;

        if (processedValue.trim() === "") {
            setPhoneNumberError("phone number cannot be empty!");
        } else if (phoneRegex.test(processedValue)) {
            setPhoneNumberError("");
        } else {
            setPhoneNumberError("phone number must contain exactly 10 digits!");
        }
    };

    const [attendanceError, setAttendanceError] = useState("select an option");
    const handleAttendanceChange = (e) => {
        if (e.target.value === 'yes' || e.target.value === 'no') {
            setAttendanceError("");
        } else {
            setAttendanceError("select an option");
        }
        setConfirmationFormData({ ...confirmationFormData, userParentAttendance: e.target.value });
    };

    const [mediumError, setMediumError] = useState("select an option");
    const handleMediumChange = (e) => {
        if (e.target.value === 'in-person tour' || e.target.value === 'virtual chat') {
            setMediumError("");
        } else {
            setMediumError("select an option");
        }
        setConfirmationFormData({ ...confirmationFormData, userTourMedium: e.target.value });
    };

    const [partySizeError, setPartySizeError] = useState("party size cannot be empty!");
    const handlePartySizeChange = (e) => {
        const partySizeValue = e.target.value;
        const partySizeRegex = /^[0-9]+$/;
    
        if (partySizeValue.trim() === "") {
            setPartySizeError("party size cannot be empty!");
            setConfirmationFormData({ ...confirmationFormData, userPartySize: partySizeValue });
        } else if (partySizeRegex.test(partySizeValue) && parseInt(partySizeValue, 10) > 0) { 
            setPartySizeError("");
            setConfirmationFormData({ ...confirmationFormData, userPartySize: partySizeValue });
        } else if (!partySizeRegex.test(partySizeValue)) {
            setPartySizeError("party size can only contain numbers!");
        } else {
            setPartySizeError("party size must be greater than 0!");
        }
    };

    const canContinueToPayment = () => {
        return (
            confirmationFormData.userTourStartDate !== "" &&
            confirmationFormData.userTourEndDate !== "" &&
            confirmationFormData.userTourStartTime !== "" &&
            confirmationFormData.userTourEndTime !== "" &&
            nameError === "" &&
            ageError === "" &&
            phoneNumberError === "" &&
            mediumError === "" &&
            attendanceError === "" &&
            partySizeError === "" &&
            (confirmationFormData.userTourMedium === 'in-person tour' ? confirmationFormData.userParentAttendance === 'yes' : true)
        );
    };

    const postData = async(confirmationFormData) => {
        try {
            const response = await axios.post('/api/airtable-student-handler', confirmationFormData);
            return response.data;
        } catch (error) {
            console.log("error posting data: ");
        }
    };

    const handleSubmit = async() => {
        const dataToSend = { ...confirmationFormData };
        delete dataToSend["Created By"];

        try {
            setLoading(true);
            await postData(dataToSend);
            return "success!";
        } catch (error) {
            console.log("error posting data: ");
            return "failure!";
        } finally {
            setLoading(false);
        };
    };

    return (
        <div className='confirmation__page-entire-container'>
            <div className='confirmation__page-top-area-container'>
                <p style={{ fontFamily: "var(--font-poppins)", fontWeight: 700, fontSize: "clamp(30px, 5vw, 55px)" }}>you're almost there!</p>
                <div className='confirmation__page-guide-rechoose-container'>
                    <p style={{ color: "#8D8D8D", fontSize: "clamp(12px, 2vw, 18px)" }}>this is the ranking of guides best matched to your search criteria</p>
                    <p style={{ color: "var(--color-orange)", fontWeight: 700, fontSize: "clamp(12px, 2vw, 18px)" }}>want to modify the rankings? do so below</p>
                    <TextArea
                        style={{ minHeight: '30px', maxHeight: '60px' }}
                        placeholder='i.e. my first preference is ranked as #3 ... i really want the second ranked student'
                        onChange={e => setConfirmationFormData({ ...confirmationFormData, userRerankMessage: e.target.value })}
                    />
                </div>
            </div>
            <div className='confirmation__page-ranking-entire-container'>
                {
                    openModal 
                    &&
                    <Modal
                        closeModal={() => setOpenModal(false)}
                        profilePhoto={getRandomImage(getPronounFolder(showUserModal.pronouns))}
                        firstName={showUserModal.firstName}
                        lastName={showUserModal.lastName}
                        major={searchMajorOptions.find(option => option.value === showUserModal.major)?.name}
                        classYear={showUserModal.classYear}
                        gender={showUserModal.gender}
                        linkedin={showUserModal.linkedin}
                        city={showUserModal.hometownCity}
                        state={showUserModal.hometownState}
                        highSchool={showUserModal.highSchool}
                        campusQuestion={showUserModal.questionCampus}
                        classQuestion={showUserModal.questionClass}
                        exciteQuestion={showUserModal.questionExcite}
                        clubsQuestion={showUserModal.questionClubs}
                        interestsQuestion={showUserModal.questionInterests}
                        messageQuestion={showUserModal.questionMessage}
                    />
                }
                {
                    users.map((user, index) => (
                        <Squareprofilecard
                            key={getUserKey(user)}
                            profilecardImage={getRandomImage(getPronounFolder(user.pronouns))}
                            profilecardTag1={getDisplayNameFromValue(user.selectedTags[0])}
                            profilecardTag2={getDisplayNameFromValue(user.selectedTags[1])}
                            profilecardTag3={getDisplayNameFromValue(user.selectedTags[2])}
                            profilecardTag4={getDisplayNameFromValue(user.selectedTags[3])}
                            profilecardPronouns={user.pronouns}
                            profilecardName={`${user.firstName} ${user.lastName} (#${index + 1})`}                            
                            profilecardMajor={searchMajorOptions.find(option => option.value === user.major)?.name}
                            profilecardYear={user.classYear}
                            profilecardHometown={user.hometownCity + ", " + user.hometownState}
                            onClick={() => openUserModal(user)}
                        />
                    ))
                }
            </div>
            <div className='confirmation__page-bottom-area-container'>
                <div className='confirmation__page-left-side-container'>
                    <div className='confirmation__page-calendar-container'>
                        <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)" }}>select best date range (or single date)</p>
                        <Calendar onChange={handleDateChange} value={date} selectRange={true} goToRangeStartOnSelect={true} />
                        {/* {rangeString && <p style={{ color: 'var(--color-orange)', fontWeight: 700, fontSize: "clamp(10px, 1.4vw, 14px)" }}>{rangeString}</p>}
                        {dateRangeError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{dateRangeError}</p>} */}
                        {
                            dateRangeError ? 
                            <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{dateRangeError}</p> :
                            rangeString && <p style={{ color: 'var(--color-orange)', fontWeight: 700, fontSize: "clamp(10px, 1.4vw, 14px)" }}>{rangeString}</p>
                        }

                    </div>
                    <div className='confirmation__page-time-container'>
                        <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)", textAlign: 'center' }}>select best tour time ranges (in pacific standard time)</p>
                        <div className='confirmation__page-time-selector-container'>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>

                                <div
                                    style={{ display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }}
                                >
                                    <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)", color: "var(--color-orange)" }}>from</p>
                                    <TimePicker
                                        open={isStartTimePickerOpen}
                                        onOpenChange={setIsStartTimePickerOpen}
                                        // onOpenChange={handleStartTimeOpenChange}
                                        format="h:mm a"
                                        placeholder='select earliest time'
                                        style={{ width: '11rem' }}
                                        onChange={handleStartTimeChange}
                                    />
                                </div>
                                {startTimeError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{startTimeError}</p>}


                            </div>
                            {/* <div
                                style={{ display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }}
                            >
                                <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)", color: "var(--color-orange)" }}>from</p>
                                <TimePicker
                                    open={isStartTimePickerOpen}
                                    onOpenChange={setIsStartTimePickerOpen}
                                    // onOpenChange={handleStartTimeOpenChange}
                                    format="h:mm a"
                                    placeholder='select earliest time'
                                    style={{ width: '11rem' }}
                                    onChange={handleStartTimeChange}
                                />
                            </div> */}
                            

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <div
                                style={{ display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }}
                            >
                                <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)", color: "var(--color-orange)" }}>to</p>
                                <TimePicker
                                    open={isEndTimePickerOpen}
                                    onOpenChange={setIsEndTimePickerOpen}
                                    format="h:mm a"
                                    placeholder='select latest time'
                                    style={{ width: '11rem' }}
                                    onChange={handleEndTimeChange}
                                />
                            </div>
                            {endTimeError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{endTimeError}</p>}
                            </div>

                            
                        </div>
                    </div>
                    <div className='confirmation__page-text-area-container'>
                        <p style={{ fontWeight: 700, fontSize: "clamp(12px, 2vw, 20px)" }}>anything else you want us to know?</p>
                        <textarea
                            className='confirmation__page-text-area'
                            placeholder='use this space to discuss accomodations, talk about scheduling, provide extra information, etc.'
                            onChange={e => setConfirmationFormData({ ...confirmationFormData, userExtraInfoMessage: e.target.value })}
                            maxLength={750}
                        />
                    </div>
                </div>
                <div className='confirmation__page-right-side-container'>
                    <div className='confirmation__page-right-side-personal-information-container'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div className='confirmation__page-personal-information-info'>
                                <p>your full legal name</p>
                                <input
                                    className='confirmation__page-info-text-container'
                                    type='text'
                                    placeholder='enter full name (of student)'
                                    value={confirmationFormData.userName}
                                    onChange={handleNameChange}
                                />
                                {nameError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{nameError}</p>}
                            </div>
                            <div className='confirmation__page-personal-information-info'>
                                <p>age</p>
                                <input
                                    className='confirmation__page-info-text-container'
                                    type='text'
                                    placeholder='enter age (of student)'
                                    value={confirmationFormData.userAge}
                                    onChange={handleAgeChange}
                                />
                                {ageError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{ageError}</p>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div className='confirmation__page-personal-information-info'>
                                <p>phone number</p>
                                <input
                                    className='confirmation__page-info-text-container'
                                    type='text'
                                    placeholder='enter phone number (of student)'
                                    value={confirmationFormData.userPhoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                                {phoneNumberError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{phoneNumberError}</p>}
                            </div>
                            <div className='confirmation__page-personal-information-info'>
                                <p>party size</p>
                                <input
                                    className='confirmation__page-info-text-container'
                                    type='text'
                                    placeholder='total number of people attending (including yourself)'
                                    value={confirmationFormData.userPartySize}
                                    onChange={handlePartySizeChange}
                                />
                                {partySizeError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{partySizeError}</p>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div className='confirmation__page-personal-information-info'>
                                <p>what type of experience do you want?</p>
                                <label style={{ overflow: 'visible', cursor: 'pointer' }}>
                                    <input
                                        type='radio'
                                        name='medium'
                                        value='in-person tour'
                                        style={{ marginRight: "5px" }}
                                        checked={confirmationFormData.userTourMedium === 'in-person tour'}
                                        onChange={handleMediumChange}
                                    /> 
                                    in-person
                                </label>
                                <label style={{ overflow: 'visible', cursor: 'pointer' }}>
                                    <input
                                        type='radio'
                                        name='medium'
                                        value='virtual chat'
                                        style={{ marginRight: "5px" }}
                                        checked={confirmationFormData.userTourMedium === 'virtual chat'}
                                        onChange={handleMediumChange}
                                    /> 
                                    virtual
                                </label>
                                {mediumError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{mediumError}</p>}
                            </div>
                            <div className='confirmation__page-personal-information-info'>
                                <p>will a parent/legal guardian be attending?</p>
                                <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px', textDecoration: 'underline' }}>if you are booking an in-person tour, at least 1 parent/legal guardian must be present.</p>
                                <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px', textDecoration: 'underline' }}>ember guides refuse the right to begin the in-person tour if a parent/legal guardian is not in attendance.</p>
                                <label style={{ overflow: 'visible', cursor: 'pointer' }}>
                                    <input
                                        type='radio'
                                        name='attendance'
                                        value='yes'
                                        style={{ marginRight: "5px" }}
                                        checked={confirmationFormData.userParentAttendance === 'yes'}
                                        onChange={handleAttendanceChange}
                                    /> 
                                    yes
                                </label>
                                <label style={{ overflow: 'visible', cursor: 'pointer' }}>
                                    <input
                                        type='radio'
                                        name='attendance'
                                        value='no'
                                        style={{ marginRight: "5px" }}
                                        checked={confirmationFormData.userParentAttendance === 'no'}
                                        onChange={handleAttendanceChange}
                                    /> 
                                    no
                                </label>
                                {attendanceError && <p style={{ color: "red", fontWeight: 'normal', fontSize: '12px' }}>{attendanceError}</p>}
                            </div>
                        </div>
                        <div className='confirmation__page-right-side-payment-container'>
                            <div className='confirmation__page-payment-button'>
                                {
                                    loading ?
                                    <div className="loadingspinner"></div> :
                                    <button 
                                        style={{
                                            fontWeight: 700,
                                            fontFamily: "var(--font-poppins)",
                                            backgroundColor: canContinueToPayment() ? "var(--color-orange)" : "#8D8D8D",
                                            cursor: canContinueToPayment() ? "pointer" : "default"
                                        }}
                                        disabled={!canContinueToPayment()}
                                        onClick={() => {
                                            const response = handleSubmit();
                                            response.then(result => {
                                                window.location.href = '/payment';
                                            }).catch(error => {
                                                console.log("Promise was rejected with:", error);
                                            });
                                        }}
                                    >
                                        confirm and pay!
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollDownArrow />
        </div>
    )
}

export default Confirmation_Page