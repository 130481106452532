import React, { Component } from "react";
import MultiSelect from "./formcontainercomponents/MultiSelectCheckboxes";

// const options = [
//   { name: "Facilities", value: "facilities" },
//   { name: "Finance", value: "finance" },
//   { name: "Front Office", value: "front_office" },
//   { name: "Human Resources", value: "human_resources" },
//   { name: "IT", value: "it" },
//   { name: "Management Team", value: "management_team" },
//   { name: "Planning", value: "planning" },
//   { name: "Sales", value: "sales" }
// ];
// const defaultValues = [
//   { name: "Management Team", value: "management_team" },
//   { name: "Sales", value: "sales" }
// ];

class FormContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
            resetSelectedOptions: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleClearForm = this.handleClearForm.bind(this);
    }

    handleMultiChange = options => {
        this.setState({ selectedOptions: options }, () => {
            console.log(this.state.selectedOptions);
        });
    };

    handleClearForm(e) {
        e.preventDefault();
        this.setState({
            selectedOptions: [],
            resetSelectedOptions: true
            // selectedOptions: defaultValues
        });
        // window.location.reload();
    }

    handleFormSubmit(e) {
        e.preventDefault();

        const formPayload = {
            selectedOptions: this.state.selectedOptions
        };
    }

    render() {

        const { options, titleLabel, label, selectedOptions, onSelectionChange } = this.props;
        const { resetSelectedOptions } = this.state;
        
        return (
            <div>
                <form
                    id="create-course-form"
                    className="container"
                    onSubmit={this.handleFormSubmit}
                    style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px' }}
                >
                    <label style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                        {titleLabel}
                    </label>
                    <MultiSelect
                        options={options}
                        label={label}
                        // defaultValues={defaultValues}
                        // name="department"
                        selectedOptions={selectedOptions}
                        resetSelectedOptions={resetSelectedOptions}
                        onChange={onSelectionChange}
                        // onChange={this.handleMultiChange}
                    />
                </form>
                {/* <button
                    onClick={this.handleClearForm}
                >
                Clear form
                </button> */}
            </div>
        );
    }
}

export default FormContainer;