// import React from "react";
// import Pricingcard from "./components/pricingcard/Pricingcard";
// import './pricingsection.css';

// const Pricingsection = () => {
//     return (
//         <div className="pricingsection__entire-container">
//             <div className="pricingsection__top-area-container">
//                 <h1 style={{ fontWeight: 700, fontSize: "clamp(75px, 15vw, 100px)", color: "var(--color-orange)", padding: "0 20px" }}>pricing</h1>
//                 <p style={{ fontWeight: 700, fontSize: "22px", padding: "0 20px 10px 20px" }}>choose what's right for your needs</p>
//                 <p style={{ padding:"0 20px" }}>at ember, we're leveling the playing field by making our experiences accessible to everyone</p>
//             </div>
//             <div className="pricingsection__bottom-area-container">
//                 <div className="pricingsection__pricing-cards-container">
//                     <Pricingcard
//                         medium="virtual (30 mins)"
//                         price="$20"
//                         subtext="all money goes to your guide"
//                         featureText="quick and easy from the comfort of your home"
//                         featureList={[
//                             "30 minutes of 1-on-1 time with current student", 
//                             "answers to how the university will fit your needs, and to any other question you may have",
//                             "casual and personalized"
//                         ]}
//                     />
//                     <Pricingcard
//                         medium="virtual (60 mins)"
//                         price="$40"
//                         subtext="all money goes to your guide"
//                         featureText="get a more in-depth understanding of the school"
//                         featureList={[
//                             "60 minutes of 1-on-1 time with current student", 
//                             "answers to how the university will fit your needs, and to any other question you may have",
//                             "casual and personalized"
//                         ]}
//                     />
//                     <Pricingcard
//                         medium="in-person (1 hour)"
//                         price="$50"
//                         subtext="all money goes to your guide"
//                         featureText="a personalized tour of the campus"
//                         featureList={[
//                             "60 minutes of 1-on-1 time with current student", 
//                             "an in-person tour of the campus, with information curated to your preferences",
//                             "casual and personalized"
//                         ]}
//                     />
//                     <Pricingcard
//                         medium="in-person (2 hours)"
//                         price="$100"
//                         subtext="all money goes to your guide"
//                         featureText="the best way of understanding how the school will fit you"
//                         featureList={[
//                             "120 minutes of 1-on-1 time with current student", 
//                             "an in-person tour of the campus, with information curated to your preferences",
//                             "casual and personalized"
//                         ]}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Pricingsection;
















import React from "react";
import Pricingcard from "./components/pricingcard/Pricingcard";
import './pricingsection.css';

const Pricingsection = () => {
    return (
        <div className="pricingsection__entire-container">
            <div className="pricingsection__top-area-container">
                <h1 style={{ fontWeight: 700, fontSize: "clamp(60px, 10vw, 100px)", color: "var(--color-orange)", padding: "0 20px" }}>pricing</h1>
                <p style={{ fontWeight: 700, fontSize: "22px", padding: "0 20px 10px 20px" }}>choose what's right for your needs</p>
                <p style={{ padding:"0 20px" }}>at ember, we're leveling the playing field by making our experiences accessible to everyone</p>
            </div>
            <div className="pricingsection__bottom-area-container">
                <div className="pricingsection__pricing-cards-container">
                    <Pricingcard
                        medium="virtual (30 mins)"
                        price="$20"
                        subtext="video chat"
                        featureText="a quick and casual 'ask me anything' session"
                        featureList={[
                            "have any question answered by a current student",
                            "rapid fire questions are encouraged; you don't have to hold back!",
                            "academics, admissions, etc."
                        ]}
                    />
                    <Pricingcard
                        medium="virtual (60 mins)"
                        price="$40"
                        subtext="video chat"
                        featureText="get a more in-depth understanding of the school"
                        featureList={[
                            "an opportunity for more in-depth conversations",
                            "extended session for more comprehensive insights",
                            "ideal for more detailed discussions on specific topics"
                        ]}
                    />
                    <Pricingcard
                        medium="in-person (45 minutes)"
                        price="$45"
                        subtext="coffee chat"
                        featureText="an on campus, face-to-face conversation with a student"
                        featureList={[
                            "the best way to feel out the campus culture via a casual conversation",
                            "witness student-to-student interactions",
                            "build personal rapport"
                        ]}
                    />
                    <Pricingcard
                        medium="in-person (1.5 hours)"
                        price="$75"
                        subtext="campus tour"
                        featureText="a personalized tour of the campus"
                        featureList={[
                            "see what you wouldn't see on a university sponsored tour",
                            "dive deeper into academic facilities tailored to your interests",
                            <>take a tour centered around what matters to <span style={{ textDecoration: 'underline' }}>you</span></>,
                        ]}
                    />
                </div>
                <div className="pricingsection__pricing-cards-container-break2">
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', columnGap: '25vw', justifyContent: 'center' }}>
                        <Pricingcard
                            medium="virtual (30 mins)"
                            price="$25"
                            subtext="video chat"
                            featureText="a quick and casual 'ask me anything' session"
                            featureList={[
                                "have any question answered by a current student",
                                "rapid fire questions are encouraged; you don't have to hold back!",
                                "academics, admissions, etc."
                            ]}
                        />
                        <Pricingcard
                            medium="virtual (60 mins)"
                            price="$45"
                            subtext="video chat"
                            featureText="get a more in-depth understanding of the school"
                            featureList={[
                                "an opportunity for more in-depth conversations",
                                "extended session for more comprehensive insights",
                                "ideal for more detailed discussions on specific topics"
                            ]}
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', columnGap: '25vw', paddingTop: '50px', justifyContent: 'center' }}>
                        <Pricingcard
                            medium="in-person (45 minutes)"
                            price="$50"
                            subtext="coffee chat"
                            featureText="an on campus, face-to-face conversation with a student"
                            featureList={[
                                "the best way to feel out the campus culture via a casual conversation",
                                "witness student-to-student interactions",
                                "build personal rapport"
                            ]}
                        />
                        <Pricingcard
                            medium="in-person (1.5 hours)"
                            price="$80"
                            subtext="campus tour"
                            featureText="a personalized tour of the campus"
                            featureList={[
                                "see what you wouldn't see on a university sponsored tour",
                                "dive deeper into academic facilities tailored to your interests",
                                <>take a tour centered around what matters to <span style={{ textDecoration: 'underline' }}>you</span></>,
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricingsection;