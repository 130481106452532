// import React from 'react';
// import './App.css';
// import { Routes, Route } from 'react-router-dom';
// import { Coming_Soon_Page, Guideonboard_Page, Tourguideonboard_Page, Touristonboard_Page, Landing_Page, Signin_Page, Signup_Page, TGDashboard_Page, Profile_Display_Page, Search_Page, Confirmation_Page, Newsignin, Newsignup, Newsearch_Page } from './pages';
// import Newdashboard from './pages/newdashboard/Newdashboard';

// import { AuthProvider } from './contexts/AuthContext';
// import PrivateRoute from './contexts/PrivateRoute';

// const App = () => {
//   return (
//     // <div>
//       <Routes>
//         <Route path='/' element={< Landing_Page />} />
//         <Route path='/guideonboard' element={< Guideonboard_Page />} />
//         <Route path='/tourguideonboard' element={< Tourguideonboard_Page />} />
//         <Route path='/touristonboard' element={< Touristonboard_Page />} />
//         <Route path='/signin' element={< Signin_Page />} />
//         <Route path='/signup' element={< Signup_Page />} />
//         <Route path='/comingsoon' element={<Coming_Soon_Page />} />
//         <Route path='/tgdashboard' element={<TGDashboard_Page />} />
//         <Route path='/profiledisplay' element={<Profile_Display_Page />} />
//         <Route path='/search' element={<Search_Page />} />
//         <Route path='/confirm' element={<Confirmation_Page />} />
//         <Route path='/newsignin' element={<Newsignin />} />
//         <Route path='/newsignup' element={<Newsignup />} />
//         <Route path='/newsearch' element={<Newsearch_Page />} />
//         <Route path='/newdashboard' element={<Newdashboard />} />
//       </Routes>
//   )
// }

// export default App

import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { 
    Coming_Soon_Page, Guideonboard_Page, Tourguideonboard_Page, 
    Touristonboard_Page, Landing_Page, Signin_Page, Signup_Page, 
    TGDashboard_Page, Profile_Display_Page, Search_Page, 
    Confirmation_Page, Newsignin, Newsignup, Newsearch_Page 
} from './pages';
import { Analytics } from '@vercel/analytics/react';
import Newdashboard from './pages/newdashboard/Newdashboard';
import Newconfirmation_Page from './pages/newconfirmation_page/Newconfirmation_Page';
import Guide_Done_Page from './pages/guide_done_page/Guide_Done_Page';
import Booking_Confirmation_Page from './pages/booking_confirmation_page/Booking_Confirmation_Page';
import About_Page from './pages/about_page/About_Page';

import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './contexts/ProtectedRoute';
import { ConfirmationProvider } from './contexts/ConfirmationContext';

// import PricingPage from './pages/PricingPage';
import Pricing_Page from './pages/pricing_page/Pricing_Page';

const App = () => {
  return (
    <AuthProvider>
      {/* <ConfirmationProvider> */}
      <Routes>
        <Route 
          path='/tourguideonboard'
          element={
            <ProtectedRoute role="guide">
              <Tourguideonboard_Page />
            </ProtectedRoute>
          }
        />
        {/* <Route 
          path='/tgdashboard'
          element={
            <ProtectedRoute>
              <TGDashboard_Page />
            </ProtectedRoute>
          }
        /> */}
        <Route 
          path='/confirm'
          element={
            <ProtectedRoute role="student">
              <Confirmation_Page />
            </ProtectedRoute>
          }
        />
        <Route 
          path='/newsearch'
          element={
            <ProtectedRoute role="student">
              <Newsearch_Page />
            </ProtectedRoute>
          }
        />
        <Route
          path='/guidedashboard'
          element={
            <ProtectedRoute role="guide">
              <Guide_Done_Page />
            </ProtectedRoute>
          }
        />

        <Route
          path='/payment'
          element={
            <ProtectedRoute role="student">
              <Pricing_Page />
            </ProtectedRoute>
          }
        />

        <Route
          path='/bookingconfirmation'
          element={
            <ProtectedRoute role="student">
              <Booking_Confirmation_Page />
            </ProtectedRoute>
          }
        />


        <Route path='/' element={<Landing_Page />} />
        <Route path='/newsignup' element={<Newsignup />} />
        <Route path='/newsignin' element={<Newsignin />} />
        <Route path='/comingsoon' element={<Coming_Soon_Page />} />
        {/* <Route path='/about' element={<About_Page />} /> */}
        <Route path='/about' element={<About_Page />} />
        {/* <Route path='/search' element={<Search_Page />} /> */}
        {/* <Route path='/newconfirm' element={<Newconfirmation_Page />} /> */}
        {/* <Route path='/payment' element={<Pricing_Page />} /> */}
        <Route path='*' element={<Coming_Soon_Page />} />

        {/* <Route path='/touristonboard' element={<Touristonboard_Page />} /> */}
        {/* <Route path='/signin' element={<Signin_Page />} /> */}
        {/* <Route path='/signup' element={<Signup_Page />} /> */}
        {/* <Route path='/tgdashboard' element={<TGDashboard_Page />} /> */}
        {/* <Route path='/profiledisplay' element={<Profile_Display_Page />} /> */}
        {/* <Route path='/search' element={<Search_Page />} /> */}
        {/* <Route path='/confirm' element={<Confirmation_Page />} /> */}
        {/* <Route path='/newsearch' element={<Newsearch_Page />} /> */}
        {/* <Route path='/newdashboard' element={<Newdashboard />} /> */}
        {/* <Route path='/guideonboard' element={<Guideonboard_Page />} /> */}
      </Routes>
      <Analytics />
      {/* </ConfirmationProvider> */}
    </AuthProvider>
  )
}

export default App;
