import React, { useState, useEffect } from 'react';
import rithika from '../../assets/rithika.jpeg';
import linkedin from '../../assets/linkedin-logo.png';
// import './profile_display_page.css';

import styles from './profile_display_page.module.css';
// started Thursday, July 27, 2023 @ 3:15 PM PST

const Profile_Display_Page = ( {
                                profileUniversity,
                                profilePhoto,
                                profileFirstName,
                                profileLastName,
                                profileMajor,
                                profileClass,
                                profileGender,
                                profileLinkedin,
                                profileCity,
                                profileState,
                                profileHighSchool,
                                profileCampusQuestion,
                                profileClassQuestion,
                                profileExciteQuestion,
                                profileClubsQuestion,
                                profileInterestsQuestion,
                                profileMessageQuestion,
                                profileEndButton1OnclickAction,
                                profileEndButton1Visibility,
                                profileEndButton1Label,
                                profileEndButton2OnclickAction,
                                profileEndButton2Visibility,
                                profileEndButton2Label,
                                profileEndButton3OnclickAction,
                                profileEndButton3Visibility,
                                profileEndButton3Label,
                                } ) => {
    // const [randomPhoto, setRandomPhoto] = useState('');
    // useEffect(() => {
    //     const getRandomPhoto = () => {
    //         const photoFiles = require.context(`../../assets/random_profile_photos`, false, /\.(png|jpe?g|svg)$/);
    //         const photos = photoFiles.keys().map(photoFiles);

    //         const randomIndex = Math.floor(Math.random() * photos.length);
    //         return photos[randomIndex];
    //     };
    //     setRandomPhoto(getRandomPhoto());
    // }, []);










    // return (
    //     <div className='profiledisplay__entire-container'>
    //         <div className='profiledisplay__left-side-container'>
    //             <div className='profiledisplay__left-top-area-container'>
    //                 <div className='profiledisplay__left-top-area-information-container'>
    //                     <img className='profiledisplay__image' src={profilePhoto} alt='photo' />
    //                     {/* <img className='profiledisplay__image' src={rithika} alt='photo' /> */}
    //                     <p>{profileFirstName} {profileLastName}</p>
    //                     {/* <p>rithika jayraman</p> */}
    //                     <div className='profiledisplay__left-top-area-specifics-container'>
    //                         <p>major: {profileMajor}</p>
    //                         <p>class: {profileClass}</p>
    //                         <p>gender: {profileGender}</p>
    //                         {/* <p>major: bioengineering</p>
    //                         <p>class: senior</p>
    //                         <p>gender: female</p> */}
    //                     </div>
    //                 </div>
    //                 <div className='profiledisplay__left-top-area-university-container'>
    //                     <p>stanford university</p>
    //                 </div>
    //             </div>
    //             <div className='profiledisplay__bar'>bar</div>
    //             <div className='profiledisplay__left-bottom-area-container'>
    //                 <a href={profileLinkedin} target="_blank"><img className='profiledisplay__linkedin' src={linkedin} alt='linkedin'/></a>
    //                 <div className='profiledisplay__left-bottom-text-container'>
    //                     <p>{profileCity}, {profileState}</p>
    //                     <p>{profileHighSchool}</p>
    //                     {/* <p>atlanta, georgia</p>
    //                     <p>lambert high school</p> */}
    //                 </div>
    //             </div>
    //         </div>
    //         <div className='profiledisplay__right-side-container'>
    //             <div className='profiledisplay__right-side-top-part-container'>
    //                 <h1><u>{profileFirstName} in six questions</u></h1>
    //                 {/* <h1><u>rithika in six questions</u></h1> */}
    //             </div>
    //             <div className='profiledisplay__right-side-information'>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>favorite thing to do on campus</h1>
    //                     <p>{profileCampusQuestion}</p>
    //                     {/* <p>i like to fountain hop and read at green! then when I get tired of studying, i go to coupa!</p> */}
    //                 </div>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>favorite class</h1>
    //                     <p>{profileClassQuestion}</p>
    //                     {/* <p>easily cs106b (i love coding!)</p> */}
    //                 </div>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>most exciting part about campus</h1>
    //                     <p>{profileExciteQuestion}</p>
    //                     {/* <p>you'll see when you get here!</p> */}
    //                 </div>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>clubs/organizations</h1>
    //                     <p>{profileClubsQuestion}</p>
    //                     {/* <p>women who invest, bases, women's superfly, south asians @ stanford</p> */}
    //                 </div>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>general interests</h1>
    //                     <p>{profileInterestsQuestion}</p>
    //                     {/* <p>cancer research, wage gap</p> */}
    //                 </div>
    //                 <div className={styles.profiledisplay__question}>
    //                     <h1>message</h1>
    //                     <p>{profileMessageQuestion}</p>
    //                     {/* <p>cancer research, wage gap</p> */}
    //                 </div>
    //                 {/* <div className='profiledisplay__question-last'>
    //                     <div className='profiledisplay__question-last-info'>
    //                         <h1>message</h1>
    //                         <p>{profileMessageQuestion}</p>
    //                     </div>
    //                     <div className='profiledisplay__randomphoto-container'>
    //                         <img src={randomPhoto}/>
    //                     </div>
    //                 </div> */}
    //                 <div className='profiledisplay__button-confirmation-container'>
    //                     {profileEndButton1Visibility !== 'hidden' && (
    //                         <button className={styles.profiledisplay__lastButton} onClick={profileEndButton1OnclickAction}>{profileEndButton1Label}</button>
    //                     )}
    //                     {profileEndButton2Visibility !== 'hidden' && (
    //                         <button className={styles.profiledisplay__lastButton} onClick={profileEndButton2OnclickAction}>{profileEndButton2Label}</button>
    //                     )}
    //                     {profileEndButton3Visibility !== 'hidden' && (
    //                         <button className={styles.profiledisplay__lastButton} onClick={profileEndButton3OnclickAction}>{profileEndButton3Label}</button>
    //                     )}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )































    return (
        <div className={styles.profiledisplay__entireContainer}>
            <div className={styles.profiledisplay__leftSideContainer}>
                <div className={styles.profiledisplay__leftTopAreaContainer}>
                    <div className={styles.profiledisplay__leftTopAreaInformationContainer}>
                        <img className={styles.profiledisplay__image} src={profilePhoto} alt='photo' />
                        {/* <img className='profiledisplay__image' src={rithika} alt='photo' /> */}
                        <p>{profileFirstName} {profileLastName}</p>
                        {/* <p>rithika jayraman</p> */}
                        <div className={styles.profiledisplay__leftTopAreaSpecificsContainer}>
                            <p>major: {profileMajor}</p>
                            <p>class: {profileClass}</p>
                            <p>gender: {profileGender}</p>
                            {/* <p>major: bioengineering</p>
                            <p>class: senior</p>
                            <p>gender: female</p> */}
                        </div>
                    </div>
                    <div className={styles.profiledisplay__leftTopAreaUniversityContainer}>
                        {/* <p>stanford university</p> */}
                        <p>{profileUniversity}</p>
                    </div>
                </div>
                <div className={styles.profiledisplay__bar}>bar</div>
                <div className={styles.profiledisplay__leftBottomAreaContainer}>
                    <a href={profileLinkedin} target="_blank" rel="noopener noreferrer"><img className={styles.profiledisplay__linkedin} src={linkedin} alt='linkedin'/></a>
                    <div className={styles.profiledisplay__leftBottomTextContainer}>
                        <p>{profileCity}, {profileState}</p>
                        <p>{profileHighSchool}</p>
                        {/* <p>atlanta, georgia</p>
                        <p>lambert high school</p> */}
                    </div>
                </div>
            </div>
            <div className={styles.profiledisplay__rightSideContainer}>
                <div className={styles.profiledisplay__rightSideTopPartContainer}>
                    <h1><u>{profileFirstName} in six questions</u></h1>
                    {/* <h1><u>rithika in six questions</u></h1> */}
                </div>
                <div className={styles.profiledisplay__rightSideInformation}>
                    <div className={styles.profiledisplay__question}>
                        <h1>favorite thing to do on campus</h1>
                        <p>{profileCampusQuestion}</p>
                        {/* <p>i like to fountain hop and read at green! then when I get tired of studying, i go to coupa!</p> */}
                    </div>
                    <div className={styles.profiledisplay__question}>
                        <h1>favorite class</h1>
                        <p>{profileClassQuestion}</p>
                        {/* <p>easily cs106b (i love coding!)</p> */}
                    </div>
                    <div className={styles.profiledisplay__question}>
                        <h1>most exciting part about campus</h1>
                        <p>{profileExciteQuestion}</p>
                        {/* <p>you'll see when you get here!</p> */}
                    </div>
                    <div className={styles.profiledisplay__question}>
                        <h1>clubs/organizations</h1>
                        <p>{profileClubsQuestion}</p>
                        {/* <p>women who invest, bases, women's superfly, south asians @ stanford</p> */}
                    </div>
                    <div className={styles.profiledisplay__question}>
                        <h1>general interests</h1>
                        <p>{profileInterestsQuestion}</p>
                        {/* <p>cancer research, wage gap</p> */}
                    </div>
                    <div className={styles.profiledisplay__question}>
                        <h1>message</h1>
                        <p>{profileMessageQuestion}</p>
                        {/* <p>cancer research, wage gap</p> */}
                    </div>
                    {/* <div className='profiledisplay__question-last'>
                        <div className='profiledisplay__question-last-info'>
                            <h1>message</h1>
                            <p>{profileMessageQuestion}</p>
                        </div>
                        <div className='profiledisplay__randomphoto-container'>
                            <img src={randomPhoto}/>
                        </div>
                    </div> */}
                    <div className={styles.profiledisplay__buttonConfirmationContainer}>
                        {profileEndButton1Visibility !== 'hidden' && (
                            <button className={styles.profiledisplay__lastButton} onClick={profileEndButton1OnclickAction}>{profileEndButton1Label}</button>
                        )}
                        {profileEndButton2Visibility !== 'hidden' && (
                            <button className={styles.profiledisplay__lastButton} onClick={profileEndButton2OnclickAction}>{profileEndButton2Label}</button>
                        )}
                        {profileEndButton3Visibility !== 'hidden' && (
                            <button className={styles.profiledisplay__lastButton} onClick={profileEndButton3OnclickAction}>{profileEndButton3Label}</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile_Display_Page