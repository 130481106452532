import React from "react";
import confetti1 from '../../assets/confetti-left.png';
import confetti2 from '../../assets/confetti-right.png';
import { auth } from '../../services/firebase';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Squareprofilecard } from "../newsearch_page/components";
import { useLocation } from 'react-router-dom';
import { searchTagOptions, searchMajorOptions } from "../../info/searchDictionaries";
import b1 from '../../assets/search_pfps/b/b1.svg';
import g1 from '../../assets/search_pfps/g/g1.svg';
import nb1 from '../../assets/search_pfps/nb/nb1.svg';
import sp from '../../assets/sample-profile.png';
import './guide_done_page.css';

const Guide_Done_Page = () => {
    // const location = useLocation();
    // const user = location.state && location.state.dataToSend;
    // if (!user) {
    //     window.location.href = '/tourguideonboard';
    // }

    // console.log(user);

    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate('/tourguideonboard');
        // navigate('/tourguideonboard', { state: { navigatePage: 3 } });
    };

    const handleInfoClick = () => {
        window.open("https://tourember.notion.site/you-re-a-guide-275d3d1061ee4831bbca2625186af30e", "_blank");
    };

    const handleSignOutClick = async () => {
        try {
            await auth.signOut();
            // window.location.href = "http://localhost:3000";
            window.location.href = "https://www.tourember.com";
        } catch (error) {
            console.error("error signing out:", error);
        }
    };

    function getDisplayNameFromValue(value) {
        const tagOption = searchTagOptions.find(option => option.value === value);
        if (tagOption) {
            return tagOption.name.split(" ")[0];
        }
        return null;
    };

    function getRandomImage(folder) {
        const randomIndex = Math.floor(Math.random() * pfpImages[folder].length);
        return pfpImages[folder][randomIndex];
    };
    
    function getPronounFolder(pronouns) {
        if (pronouns === "he/him") {
            return 'b';
        } else if (pronouns === "she/her") {
            return 'g';
        } else {
            return 'nb';
        }
    };

    const pfpImages = {
        // b: [b1, b2, b3, b4, b5, b6],
        // g: [g1, g2, g3, g4, g5, g6],
        // nb: [nb1, nb2, nb3, nb4, nb5, nb6],
        b: [b1],
        g: [g1],
        nb: [nb1],
    };

    const [showUserModal, setShowUserModal] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const openUserModal = (user) => {
        setShowUserModal(user);
        setOpenModal(true);
    };


    return (
        <div className="guidedone__entire-container">
            <div className="guidedone__top-area-container">
                <div className="guidedone__top-area-text-container">
                {/* <Squareprofilecard
                                    profilecardImage={getRandomImage(getPronounFolder(user.pronouns))}
                                    profilecardTag1={getDisplayNameFromValue(user.selectedTags[0])}
                                    profilecardTag2={getDisplayNameFromValue(user.selectedTags[1])}
                                    profilecardTag3={getDisplayNameFromValue(user.selectedTags[2])}
                                    profilecardTag4={getDisplayNameFromValue(user.selectedTags[3])}
                                    profilecardPronouns={user.pronouns}
                                    profilecardName={user.firstName + " " + user.lastName}
                                    profilecardMajor={searchMajorOptions.find(option => option.value === user.major)?.name}
                                    profilecardYear={user.classYear}
                                    profilecardHometown={user.hometownCity + ", " + user.hometownState}
                                    onClick={() => openUserModal(user)}
                                /> */}
                    <h1special>welcome to ember!</h1special>
                </div>
                <div className="guidedone__top-area-buttons-container">
                    <div className="guidedone__buttons-button" onClick={handleProfileClick}>
                        <span>view profile</span>
                    </div>
                    <div className="guidedone__buttons-button" onClick={handleInfoClick}>
                        <span>how to give a tour</span>
                    </div>
                    <div className="guidedone__buttons-button" onClick={handleSignOutClick}>
                        <span>sign out</span>
                    </div>
                </div>
                <div>
                    <p
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            textAlign: 'center',
                            justifyContent: 'center',
                            fontWeight: 700,
                            padding: '20px 50px',
                            fontSize: 'clamp(10px, 2vw, 20px)',
                            color: 'white',
                            marginBottom: '0px',
                        }}
                    >
                        as soon as you are selected by a high school student, you will receive an email with all the pertinent information!
                    </p>
                </div>
                <div className="guidedone__privacy-container">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 'clamp(9px, 1.5vw, 15px)',
                            color: 'var(--color-orange)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0px 50px',
                            textAlign: 'center'
                        }}
                    >
                        <p>at ember, we value your privacy</p>
                        <p>when a student is searching for a guide, they do not see your name, profile image, email, or linkedin. all personal info is held confidential until you accept a tour request.</p>
                        {/* <p>, no confidential information is provided.</p> */}
                    </div>
                    {/* <div style={{ width: '400px', height: '400px' }}>
                        <img src={sp} />
                    </div> */}
                </div>
            </div>
            <div className="guidedone__bottom-area-container">
                <img src={confetti1} />
                <img src={confetti2} />
            </div>
        </div>
    )
};

export default Guide_Done_Page;
