import React, { useEffect, useState } from 'react';
import alara from '../../../../assets/alara.jpeg';
import './newwhyus.css';

// given in large groups, given only during specific times, everyone sees the same things, don't get honest answers from guides

const Newwhyus = () => {
    return (
        <div className='newwhyus__total-entire-container'>
            <div className='newwhyus__entire-container'>
                <div className='newwhyus__header-text'>
                    <p>with current college tours, you'll get:</p>
                </div>
                <div className='newwhyus__problems-container'>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">large group sizes</p>
                        <p className="newwhyus__hover-text">ember = you + your guide. that's it.</p>
                    </div>
                    {/* <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">no dorm access</p>
                        <p className="newwhyus__hover-text">ember guides offer virtual tours of their dorms</p>
                    </div> */}
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">lack of authenticity</p>
                        <p className="newwhyus__hover-text">ember guides answer everything; ask questions about campus life, admissions, culture, etc.</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">short q&a time</p>
                        <p className="newwhyus__hover-text">ask our guides any questions about anything at any time during the experience</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">aesthetic emphasis</p>
                        <p className="newwhyus__hover-text">ember guides expose you to all parts of the school; even the not-so-great ones</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">brief tour duration</p>
                        <p className="newwhyus__hover-text">book up to 1.5 hours of 1-on-1 time with an ember guide</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">lack of local exploration</p>
                        <p className="newwhyus__hover-text">a university is more than its campus; ember guides are happy to show you the surrounding areas</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">cookie-cutter itineraries</p>
                        <p className="newwhyus__hover-text">our guides come with plans but they are happy to change them for what you want</p>
                    </div>
                    <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">limited availability</p>
                        <p className="newwhyus__hover-text">get access to an ember experience at any time during the year</p>
                    </div>
                    {/* <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">limited student interactions</p>
                        <p className="newwhyus__hover-text">ember guides are happy to introduce you to other students during tours to learn more</p>
                    </div> */}
                    {/* <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">no mode flexibility</p>
                        <p className="newwhyus__hover-text">ember tours need not always be walking ones; select guides accomodate biking and driving as well</p>
                    </div> */}
                    {/* <div className='newwhyus__box'>
                        <p className="newwhyus__box-text">uniform experiences</p>
                        <p className="newwhyus__hover-text">every single student taking an ember tour will have it customized for them</p>
                    </div> */}
                </div>
                <div className='newwhyus__header-text'>
                    {/* <p style={{ paddingBottom: '20px' }}>at ember, we're turning generic tours into catered personal experiences</p> */}
                    <p style={{ paddingBottom: '20px' }}>
                        at <span style={{ color: '#f4ae02' }}>ember</span>, we're turning generic tours into catered personal experiences
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Newwhyus