import React, { useState } from 'react';

import riya from '../../../../assets/vertical-riya.jpeg';
import faizan from '../../../../assets/vertical-faizan.jpeg';
import eliza from '../../../../assets/vertical-eliza.jpeg';
import christian from '../../../../assets/newchristian.jpg';
import hannah from '../../../../assets/hannahBasaliLanding.jpeg';
import check from '../../../../assets/check-mark.svg';

import Profile_Display_Page from '../../../profile_display_page/Profile_Display_Page';

import './landingpfpcontainer.css';

const guideProfiles = [
    {
        photo: riya,
        firstName: "riya",
        lastName: "narayan",
        major: "aerospace engineering",
        class: "sophomore",
        gender: "female",
        linkedin: "https://www.linkedin.com/in/riyanarayan/",
        city: "houston",
        state: "texas",
        highSchool: "cypress ridge high school",
        campusQuestion: "on a hot day, i like fountain hopping and then tanning in meyer green. bring a few friends and it's even better!",
        classQuestion: "math 53 (by far!) - ordinary differential equations and their applications",
        exciteQuestion: "you'll see when you get here!",
        clubsQuestion: "solar car, kappa kappa gamma, stanford space iniative",
        interestsQuestion: "the wage gap, fashion's impact on the economy, whether aliens are real",
        messageQuestion: "i can't wait to show you how stanford is going to fit YOU! get ready for an amazing experience!",
    },
    {
        photo: faizan,
        firstName: "faizan",
        lastName: "asif",
        major: "computer science",
        class: "senior",
        gender: "male",
        linkedin: "https://www.linkedin.com/in/faizan-asif-ba7651195/",
        city: "houston",
        state: "texas",
        highSchool: "seven lakes high school",
        campusQuestion: "socialize on the row",
        classQuestion: "think51 -- changed my outlook and position on the meaning of democracy",
        exciteQuestion: "the people and their perspectives",
        clubsQuestion: "stanford consulting, muslim student union, intramural football",
        interestsQuestion: "sports, guitar, movies, music",
        messageQuestion: "whatever expectations you have. raise them. you're still wrong.",
    },
    {
        photo: hannah,
        firstName: "hannah",
        lastName: "basali",
        major: "political science",
        class: "senior",
        gender: "female",
        linkedin: "https://www.linkedin.com/in/hannah-basali-5494871ba/",
        city: "cleveland",
        state: "ohio",
        highSchool: "hathaway brown school",
        campusQuestion: "explore all of the outdoor activities! i just joined a rock climbing class :)",
        classQuestion: "history 201c - the us, un, peacekeeping, and humanitarian war",
        exciteQuestion: "derailing your day by bumping into your friends",
        clubsQuestion: "class presidency, kappa kappa gamma, stanford pre-law society",
        interestsQuestion: "dance, proclaiming my love for cleveland, and salsa dancing (preferably under the moonlight)",
        messageQuestion: "this time of your life may seem very scary, but great days lie ahead! i can't wait to help you navigate the process!",
    },
    {
        photo: christian,
        firstName: "christian",
        lastName: "wright",
        major: "biology",
        class: "senior",
        gender: "male",
        linkedin: "https://www.linkedin.com/in/christian-wright-89a5b4217/",
        city: "dallas",
        state: "texas",
        highSchool: "highland park high school",
        campusQuestion: "eating a dining hall burrito outdoors with my friends",
        classQuestion: "bio 162: mechanisms of tissue regeneration",
        exciteQuestion: "swimming in the fountains",
        clubsQuestion: "stanford american indian organization, surgical research, cardinal free clinics",
        interestsQuestion: "medicine, intramural soccer, weight-lifting, stem",
        messageQuestion: "so excited to show you around campus!! :)",
    },
    // {
    //     photo: eliza,
    //     firstName: "eliza",
    //     lastName: "sandell",
    //     major: "history",
    //     class: "junior",
    //     gender: "female",
    //     linkedin: "https://www.linkedin.com/in/eliza-sandell-17a927168/",
    //     city: "boston",
    //     state: "massachussets",
    //     highSchool: "phillips academy",
    //     campusQuestion: "ever got lost in the art gallery, immersed in centuries of creativity? it's my favorite escape. care to join?",
    //     classQuestion: "hist 103: knights, monks, and nomads. imagine time travel, but with more interactive experiences.",
    //     exciteQuestion: "two words: moonlit archery. it's an experience that'll make you feel like a medieval legend.",
    //     clubsQuestion: "stanford theater, time-travelers club, and those super fun medieval reenactment events.",
    //     interestsQuestion: "exploring lost civilizations, the allure of vintage fashion, and spending nights decoding shakespeare's inside jokes.",
    //     messageQuestion: "together, we'll unearth stanford's hidden tales and make history. keen to dive in?",
    // },
];

const Landingpfpcontainer = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleNameClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % guideProfiles.length);
    };

    return (
        <div className='landingpfpcontainer__entire-container'>
            <div className='landingpfpcontainer__text-container'>
                <div className='landingpfpcontainer__text-left-container'>
                    <div>
                        <h1>meet { }
                            <span onClick={handleNameClick} className='landingpfpcontainer__name-pulse'>
                                {guideProfiles[currentIndex].firstName}
                            </span>, one of our guides:
                        </h1>
                        <p style={{ color: 'black', fontSize: 'clamp(6px, 1.2vw, 20px)' }}>click on the name to see other guides!</p>
                    </div>
                </div>
                {/* <div className='landingpfpcontainer__text-right-container'>
                    <div className='landingpfpcontainer__text-right-container-element'>
                        <img src={check} />
                        <p>ember makes information easy to digest</p>
                    </div>
                    <div className='landingpfpcontainer__text-right-container-element'>
                        <img src={check} />
                        <p>see more information from guides' linkedin</p>
                    </div>
                    <div className='landingpfpcontainer__text-right-container-element'>
                        <img src={check} />
                        <p>get to know the personal side of each guide</p>
                    </div>
                </div> */}
            </div>
            <Profile_Display_Page
                profileUniversity={"stanford university"}
                profilePhoto={guideProfiles[currentIndex].photo}
                profileFirstName={guideProfiles[currentIndex].firstName}
                profileLastName={guideProfiles[currentIndex].lastName}
                profileMajor={guideProfiles[currentIndex].major}
                profileClass={guideProfiles[currentIndex].class}
                profileGender={guideProfiles[currentIndex].gender}
                profileLinkedin={guideProfiles[currentIndex].linkedin}
                profileCity={guideProfiles[currentIndex].city}
                profileState={guideProfiles[currentIndex].state}
                profileHighSchool={guideProfiles[currentIndex].highSchool}
                profileCampusQuestion={guideProfiles[currentIndex].campusQuestion}
                profileClassQuestion={guideProfiles[currentIndex].classQuestion}
                profileExciteQuestion={guideProfiles[currentIndex].exciteQuestion}
                profileClubsQuestion={guideProfiles[currentIndex].clubsQuestion}
                profileInterestsQuestion={guideProfiles[currentIndex].interestsQuestion}
                profileMessageQuestion={guideProfiles[currentIndex].messageQuestion}
                profileEndButton1Visibility={"hidden"}
                profileEndButton2Visibility={"hidden"}
                profileEndButton3Visibility={"hidden"}
            />
        </div>
    )
};

export default Landingpfpcontainer