import React, { useState, useEffect } from 'react';
import emailImage from '../../assets/email.png';
import highschoolstudent from '../../assets/highschoolstudent.png';
import universitystudent from '../../assets/universitystudent.png';
import axios from 'axios';
import './newsignup.css';

const Newsignup = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const [email, setEmail] = useState('');

  const renderPage = () => {
    switch(currentPage) {
      case 0:
        return (
          <Signup1
            nextPage={nextPage}
            email={email}
            setEmail={setEmail}
          />
        );
      case 1:
        return (
          <Signup2 
            email={email}
          />
        )
      default:
        return null;
    }
  };

  return <div className="form">{renderPage()}</div>;
};

const Signup1 = ({ nextPage, email, setEmail }) => {
  const [selectedSignupType, setSelectedSignupType] = useState(null);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    try {
      setLoading(true);
      await axios.post('/api/signup', {
        email: email,
        password: password,
      });
      nextPage();
    } catch (error) {
      console.log(error);
      if (error.response?.data === 'The email address is already in use by another account.') {
        setMessage(email + ' is already in use.');
      }
    } finally {
      setLoading(false);
    }
  };  

  const [emailValid, setEmailValid] = useState(false);
  const isEmailValid = () => {
    const basicEmailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const universityPattern = /@(stanford|berkeley)\.edu$/;

    if (selectedSignupType === 'university') {
      return universityPattern.test(email);
    } else if (selectedSignupType === 'highschool') {
      return basicEmailPattern.test(email);
    } else {
      return false;
    }
  };
  useEffect(() => {
    setEmailValid(isEmailValid());
  }, [email, selectedSignupType]);

  const [confirmPassword, setConfirmPassword] = useState('');
  const isPasswordConfirmed = () => {
    return password !== '' && password === confirmPassword;
  };

  return (
    <div className='newsignin__total-background'>
      <div className='center-container'>
        <div className='centered-box'>
          <div className='newsignin__entire-container'>
            <div className='newsignup__left-side-container'>
              <div className='newsignup__type-container'>
                <p style={{fontWeight: 700, fontSize: "40px"}}>i am a</p>
                <div className='newsignup__type-images-container'>
                  <div
                    className={`newsignup__imagetext-container ${
                      selectedSignupType === 'highschool' ? 'selected' : ''
                    }`}
                    onClick={() => {setSelectedSignupType('highschool'); setEmailValid(false); setEmail('');}}
                  >
                    <img src={highschoolstudent} alt='high school student' />
                    <p>high school student</p>
                  </div>
                  <div
                    className={`newsignup__imagetext-container ${
                      selectedSignupType === 'university' ? 'selected' : ''
                    }`}
                    onClick={() => {setSelectedSignupType('university'); setEmailValid(false); setEmail('');}}
                  >
                    <img src={universitystudent} alt='university student' />
                    <p>prospective guide</p>
                  </div>
                </div>
              </div>
              <div className='newsignup__signup-actual-container'>
                <div className='newsignup__create-account-containers'>
                  <input
                    style={{
                      width: "100%",
                      borderColor: emailValid ? 'green' : 'red'
                    }}
                    type='textarea'
                    placeholder={
                      selectedSignupType === null
                        ? 'select account type'
                        : selectedSignupType === 'university'
                        ? 'email (.edu)'
                        : 'personal email'
                    }
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setEmailValid(isEmailValid());
                    }}
                    disabled={selectedSignupType === null}
                  />
                  <div className='newsignup__create-account-row3'>
                    <input 
                      type='password'
                      placeholder='password (min 6 characters)'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      style={{
                        borderColor: password.length >= 6 ? 'green' : 'red'
                      }}
                    />
                    <input 
                      type='password'
                      placeholder='confirm password'
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      style={{
                        borderColor: isPasswordConfirmed() ? 'green' : 'red'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="newsignup_button-container">
                <div className='newsignup__signup-button-container'>
                  {
                    loading ?
                    <div className="loadingspinner"></div> :
                    <button
                      onClick={handleSignUp}
                      disabled={!emailValid || password.length < 6 || !isPasswordConfirmed()}
                      style={(!emailValid || password.length < 6 || !isPasswordConfirmed()) ? { opacity: 0.5 } : {}}
                    >
                      sign up
                    </button>
                  }
                </div>
                <div className='newsignup__signin-redirect' style={{fontSize: ".85vw"}}>
                  <p style={{ fontSize: 'clamp(8px, .75vw, 12px)' }}>already have an account?</p>
                  <a href='/newsignin' style={{ fontWeight: 700, fontSize: 'clamp(8px, .75vw, 12px)', color: 'var(--color-orange)', cursor: 'pointer' }}>sign in</a>
                </div>
              </div>
              {
                message && 
                <div className='newsignup__signup-messages'>
                  <p>{message}</p>
                </div>
              }
            </div>
            <div className='newsignup__right-side-container'>
              <h1>hey there, welcome to ember's beta release! 🎉</h1>
              {/* <p style={{ fontWeight: 'bold' }}>you are part of a beta release, and we're thrilled to have you!</p> */}
              <div className='newsignup__right-side-container-message-container'>
                <p style={{ }}>here's what that means for you:</p>
                <div className='newsignup__right-side-container-meaning-list'>
                  <li>we're actively working on the platform's development to improve your experience</li>
                  <li>If you have any thoughts or issues (big or small), feel free to email us at <a style={{ color: '#26153a', fontWeight: 'bold', textDecoration: 'underline' }} href="mailto:admin@tourember.com">admin@tourember.com</a></li>
                  <li>ember is only live at stanford university - for now at least :)</li>
                </div>
              </div>
              <p>we hope you enjoy your curated ember experience!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Signup2 = ({ email }) => {
  return (
    <div className='newsignin__total-background'>
      <div className='center-container'>
        <div className='centered-box'>
          <div className='newsignin__entire-container'>
            <div className='emailconfirm__left-side-container'>
              <div className='emailconfirm__left-side-top-container'>
                <h1>you're almost there!</h1>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <p>to start using ember, click on the verification link we sent to</p>
                  <p style={{ fontWeight: 700, textDecoration: "underline", color: "var(--color-orange)" }}>{email}</p>
                </div>
              </div>
              <div className='emailconfirm__left-side-image-container'>
                <img src={emailImage} alt='email' />
              </div>
              <div className='emailconfirm__left-side-bottom-container'>
                <p>verified?</p>
                <a href='/newsignin' style={{ fontWeight: 700, textDecoration: "underline", color: "var(--color-orange)", cursor: "pointer" }}>sign in</a>
              </div>
            </div>
            <div className='newsignup__right-side-container'>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsignup