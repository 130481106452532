import React from "react";
import './squarebox.css';

const Squarebox = ({ rankNumber }) => {
    return (
        <div className="squarebox__entire-container">
            <div className="squarebox__number-container">
                <h1>{rankNumber}</h1>
            </div>
        </div>
    )
};

export default Squarebox;
