import React from 'react';
import riya from '../../../../assets/transparent-riya.png';
import './tokenperson.css';
import Photoloop from '../../components/photoloop/Photoloop';
import { Colleges } from '../../components';

const Tokenperson = () => {
    // return (
    //     <div className='tokenperson__entire-container'>
    //         <div className='tokenperson__photo-container'>
    //             <img src={riya} alt='riya' />
    //         </div>
    //         <div className='tokenperson__text-container'>
    //             <p>my admissions process was defined by uncertainty. i knew where i wanted to go, but i never had a clear grasp on where i didn't want to end up. i found it difficult to understand which university would fit my needs because the information given out about universities is always generalized. the admissions process lacks authenticity when learning about what colleges truly offer, and it's why i'm using ember to give prospective students a genuine and agressively unfiltered look at the school they want.</p>
    //         </div>
    //     </div>
    // )
    return (
        <div className='tokenperson__entire-container'>
            <div style={{ display: "flex", flexDirection: "column", gap: "3vh" }}>
                <h1>here's why they joined ember</h1>
                <div className='tokenperson__quotes-container'>
                    <p>"i want to get rid of applying to schools that you don't want to go to"</p>
                    <p>"students deserve to know everything about a university before they apply"</p>
                    <p>"it's nice to know what a university offers for you, but it's better to know what it doesn't"</p>
                    <p>"i'm here because I wish i had ember when i was in high school"</p>
                    <p>"i want to tell high schoolers what universities don't want them to know"</p>
                    <p>"i'm here to show you what college is like for you"</p>
                    <p>"ember offers what no other tour ever can: exclusive personalization"</p>
                </div>
            </div>
            {/* <div className='tokenperson__separation-bar' /> */}
            {/* <div>
                <Colleges />
            </div> */}
        {/* <Photoloop /> */}
        </div>
    )
}

export default Tokenperson