import React from 'react';

import trainer from '../../../../assets/personal-trainer.png'
import architect from '../../../../assets/engineer-woman.png'
import astronaut from '../../../../assets/astronaut.png'
import pilot from '../../../../assets/pilot.png';
import dj from '../../../../assets/dj.png';
import scuba from '../../../../assets/scuba-diver-man.png';
import firefighter from '../../../../assets/firefighter.png';
import chef from '../../../../assets/chef-man.png';
import student from '../../../../assets/graduate-student.png';
import fashion from '../../../../assets/mommy-cook.png';
import motorcyclist from '../../../../assets/motorcyclist.png';
import detective from '../../../../assets/detective.png';

import Boxcomponent from '../../../mostnewsteps/boxcomponent/Boxcomponent';

import './newguidedisplay.css';

const Newguidedisplay = () => {
    return (
        <div className='newguidedisplay__entire-container'>
            <div className='newguidedisplay__text-and-images-container'>
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                    <h1>our guides represent a variety of fields and interests</h1>
                    <p style={{ color: "var(--color-orange)", fontFamily: "var(--font-poppins)", padding: "0px 3vw", fontSize: "clamp(10px, 2vw, 20px)" }}>yes, these bios represent real ember guides. and this isn't even all of them.</p>
                </div>
                <div className='newguidedisplay__boxes-container'>

                    <div className='newguidedisplay__boxes-row'>
                        <Boxcomponent
                            boxcomponentImage={trainer}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a premed student advancing regenerative athletic therapy"}
                            boxcomponentClass={"senior"}
                        />
                        <Boxcomponent
                            boxcomponentImage={architect}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a political science student with a knack for architecture"}
                            boxcomponentClass={"senior"}
                        />
                        <Boxcomponent
                            boxcomponentImage={astronaut}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a bioengineering student who wants to go to space"}
                            boxcomponentClass={"sophomore"}
                        />
                        <Boxcomponent
                            boxcomponentImage={pilot}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"an international relations student getting his pilot's license"}
                            boxcomponentClass={"junior"}
                        />
                    </div>
                    



                    <div className='newguidedisplay__boxes-row'>
                        <Boxcomponent
                            boxcomponentImage={dj}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a computer science student who djs on the side"}
                            boxcomponentClass={"senior"}
                        />
                        <Boxcomponent
                            boxcomponentImage={scuba}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a physics student who scuba dives for conservation efforts"}
                            boxcomponentClass={"sophomore"}
                        />
                        <Boxcomponent
                            boxcomponentImage={firefighter}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"an english major working as a volunteer firefighter"}
                            boxcomponentClass={"junior"}
                        />
                        <Boxcomponent
                            boxcomponentImage={student}
                            boxcomponentUniversity={"stanford"}
                            boxcomponentDescription={"a product design student pioneering equitable education"}
                            boxcomponentClass={"senior"}
                        />
                    </div>


                    
                    {/* <Boxcomponent
                        boxcomponentImage={chef}
                        boxcomponentUniversity={"stanford"}
                        boxcomponentDescription={"a chemistry student developing new-age, healthy foods"}
                        boxcomponentClass={"senior"}
                    /> */}
                    {/* <Boxcomponent
                        boxcomponentImage={fashion}
                        boxcomponentUniversity={"stanford"}
                        boxcomponentDescription={"an economics student researching sustainable fashion"}
                        boxcomponentClass={"senior"}
                    /> */}
                    {/* <Boxcomponent
                        boxcomponentImage={motorcyclist}
                        boxcomponentUniversity={"stanford"}
                        boxcomponentDescription={"a human biology student making f1 racing safer"}
                        boxcomponentClass={"sophomore"}
                    /> */}
                    {/* <Boxcomponent
                        boxcomponentImage={detective}
                        boxcomponentUniversity={"stanford"}
                        boxcomponentDescription={"a political science student changing news reporting"}
                        boxcomponentClass={"junior"}
                    /> */}
                </div>
            </div>
        </div>
    );
};

export default Newguidedisplay;