import React, { useState, useRef, useEffect, useMemo } from 'react';
import { CFormInput, CFormLabel, CFormSelect, CFormFloating } from '@coreui/react';
import { FormGroup } from '@mui/material';
import { Customcheckbox } from './components';
// import queryString from 'query-string';
import { Link, useNavigate } from 'react-router-dom';
import photographer from '../../../assets/photographer.png';
// import islandman from '../../../assets/twopeople.png'; <-- what used to be used
import islandman from '../../../assets/rocket.png';
// import emberlogo from '../../../assets/newnewemberlogo.svg';
import rightarrow from '../../../assets/right-arrow.png';
import leftarrow from '../../../assets/left-arrow.png';
// import './tourguideonboard_page.css';
import Profile_Display_Page from '../../profile_display_page/Profile_Display_Page';
import { Image } from 'cloudinary-react';
import { useLocation } from 'react-router-dom';

import { searchTagOptions, searchMajorOptions } from '../../../info/searchDictionaries';

// import handleSubmit from '../../../api/submitGuideOnboarding';

import axios from 'axios';

import { auth } from '../../../services/firebase';

import { getAuth, onAuthStateChanged } from "firebase/auth";

import styles from '../../profile_display_page/profile_display_page.module.css';
import { set } from 'lodash';

// import pfpdisplaycss from '../../profile_display_page/pfpcss.module.css';

/* started July 23, 2023 @ 10:42 PM PST (Revamping the onboarding page) */
/* actually started July 25, 2023 @ 11:09 AM PST */
/* finished @ 2:08 PM PST on July 26, 2023 */

const Tour_Guide_Onboard = ({ navigatePage }) => {
    useMemo(async () => {
        await import('bootstrap/dist/css/bootstrap.min.css');
        import('./tourguideonboard_page.css');
    }, []);

    const [currentPage, setCurrentPage] = useState(0);
    // useEffect(() => {
    //     if (props.location.state?.navigatePage !== 0) {
    //         setCurrentPage(props.location.state?.navigatePage);
    //     }
    // }, [props.location.state?.navigatePage]);

    // const [currentPage, setCurrentPage] = useState(0);
    // const location = useLocation();
    // const p = location.state?.navigatePage;
    // if (p !== 0) {
    //     setCurrentPage(p);
    // }

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        pronouns: '',
        gender: '',
        email: '',
        phoneNumber: '',
        linkedin: '',
        highSchool: '',
        hometownCity: '',
        hometownState: '',
        classYear: '',
        major: '',
        questionCampus: '',
        questionClass: '',
        questionExcite: '',
        questionClubs: '',
        questionInterests: '',
        questionMessage: '',
        selectedTags: [],
        photo: null,
        photoUrl: '',
    });

    // Fetch user data from serverless function
    const fetchUserData = async (email) => {
        // console.log("now i am in the fetch user data function");
        try {
            // console.log("now i am in the try block of the fetch user data function");
            const response = await axios.get('/api/fetch-user-data', { params: { email } });

            // console.log(response.data.selectedTags);

            // console.log("the response has just been done");
            // console.log("this is before i see the tags:", formData.selectedTags);
            if (response.data) {
                const fetchedData = { ...response.data, photo: response.data.photoUrl };
                // console.log("this is the fetched data: ", fetchedData);
                // console.log("the response has data");
                setFormData(fetchedData);
                if (fetchedData.selectedTags) {
                    setSelectedTags(fetchedData.selectedTags);
                }
                // setFormData(response.data);
                // setSelectedTags(response.data.selectedTags);
                // console.log("and this is the actual data: ", response.data);

                if (response.data.questionMessage) {
                    setIsValidFirstName(true);
                    setIsValidLastName(true);
                    setIsValidPronouns(true);
                    setIsValidGender(true);
                    setIsValidEmail(true);
                    setIsValidPhoneNumber(true);
                    setIsValidLinkedin(true);
                    setIsValidHighSchool(true);
                    setIsValidHometownCity(true);
                    setIsValidHometownState(true);
                    setIsValidClassYear(true);
                    setIsValidMajor(true);
                    setIsValidQuestionCampus(true);
                    setIsValidQuestionClass(true);
                    setIsValidQuestionExcite(true);
                    setIsValidQuestionClubs(true);
                    setIsValidQuestionInterests(true);
                    setIsValidQuestionMessage(true);
                }
            }
            // console.log("these are the airtable tags: ", formData.selectedTags);
        } catch (error) {
            console.error('Error fetching userss data:', error);
        }
    };
    useEffect(() => {
        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                const email = user.email;
                setFormData(prevFormData => ({ ...prevFormData, email }));
                // console.log("alsdkalskfd: ", email);
                fetchUserData(email);
                // console.log("i got past fetching the user data");
            }
        });
    }, []);

    const postData = async (formData) => {
        try {
            const response = await axios.post('/api/airtable-handler', formData)
            return response.data;
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const handleSubmit = async () => {
        const dataToSend = { ...formData };
        delete dataToSend.photo;
        delete dataToSend["Created By"];
        dataToSend.photoUrl = formData.photoUrl;
        try {
            setLoading(true);
            const response = await postData(dataToSend);
            // console.log('Data posted:', response);
            navigate('/guidedashboard', { state: { dataToSend } });
        } catch (error) {
            console.error('Error posting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const [isValidFirstName, setIsValidFirstName] = useState(false);
    const handleFirstNameChange = (e) => {
        const { name, value } = e.target;

        setIsValidFirstName(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidLastName, setIsValidLastName] = useState(false);
    const handleLastNameChange = (e) => {
        const { name, value } = e.target;

        setIsValidLastName(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    }

    const [isValidPronouns, setIsValidPronouns] = useState(false);
    const handlePronounsChange = (e) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]:value
        }));

        if (value === "select pronouns") {
            setIsValidPronouns(false);
        } else {
            setIsValidPronouns(true);
        }
    };

    const [isValidGender, setIsValidGender] = useState(false);
    const handleGenderChange = (e) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]: value,
        }));

        if (value === "select gender") {
            setIsValidGender(false);
        } else {
            setIsValidGender(true);
        }    
    };

    const [isValidEmail, setIsValidEmail] = useState(false);
    const handleEmailChange = (e) => {
        const { name, value } = e.target;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const preprocessPhoneNumber = (input) => {
        return input.replace(/\D/g, '');
    };
    const handlePhoneNumberChange = (e) => {
        const { name, value } = e.target;
    
        const processedValue = preprocessPhoneNumber(value);
    
        const phoneRegex = /^[0-9]{10}$/;
    
        const isPhoneValid = phoneRegex.test(processedValue);
        setIsValidPhoneNumber(isPhoneValid);
    
        setFormData((formData) => ({
            ...formData,
            [name]: processedValue,
        }));
    };

    const [isValidLinkedin, setIsValidLinkedin] = useState(false);
    const handleLinkedinChange = (e) => {
        const { name, value } = e.target;

        const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|profile)\/[a-zA-Z0-9_-]+\/?$/i;
        const isValidLinkedin = linkedinRegex.test(value);
        setIsValidLinkedin(isValidLinkedin);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidHighSchool, setIsValidHighSchool] = useState(false);
    const handleHighSchoolChange = (e) => {
        const { name, value } = e.target;

        setIsValidHighSchool(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidHometownCity, setIsValidHometownCity] = useState(false);
    const handleHometownCityChange = (e) => {
        const { name, value } = e.target;

        setIsValidHometownCity(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidHometownState, setIsValidHometownState] = useState(false);
    const handleHometownStateChange = (e) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));

        if (value === "select state") {
            setIsValidHometownState(false);
        } else {
            setIsValidHometownState(true);
        }    
    };

    const [isValidClassYear, setIsValidClassYear] = useState(false);
    const handleClassYearChange = (e) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]: value,
        }));

        if (value === "select class year") {
            setIsValidClassYear(false);
        } else {
            setIsValidClassYear(true);
        }    
    };

    const [isValidMajor, setIsValidMajor] = useState(false);
    const handleMajorChange = (e) => {
        const { name, value } = e.target;

        setFormData((formData) => ({
            ...formData,
            [name]: value,
        }));

        if (value === "searchSelectMajor") {
            setIsValidMajor(false);
        } else {
            setIsValidMajor(true);
        }    
    };

    const [isValidQuestionCampus, setIsValidQuestionCampus] = useState(false);
    const handleQuestionCampusChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionCampus(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidQuestionClass, setIsValidQuestionClass] = useState(false);
    const handleQuestionClassChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionClass(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidQuestionExcite, setIsValidQuestionExcite] = useState(false);
    const handleQuestionExciteChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionExcite(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidQuestionClubs, setIsValidQuestionClubs] = useState(false);
    const handleQuestionClubsChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionClubs(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidQuestionInterests, setIsValidQuestionInterests] = useState(false);
    const handleQuestionInterestsChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionInterests(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [isValidQuestionMessage, setIsValidQuestionMessage] = useState(false);
    const handleQuestionMessageChange = (e) => {
        const { name, value } = e.target;

        setIsValidQuestionMessage(value.trim().length > 0);

        setFormData((formData) => ({
            ...formData,
            [name]: value.toLowerCase(),
        }));
    };

    const [selectedTags, setSelectedTags] = useState([]);
    const handleTagChange = (tagValue) => {
        if (selectedTags.includes(tagValue)) {
            setSelectedTags(selectedTags.filter(tag => tag !== tagValue));
        } else if (selectedTags.length < 4) {
            setSelectedTags([...selectedTags, tagValue]);
        }
    };
    useEffect(() => {
        setFormData({ ...formData, selectedTags });
    }, [selectedTags])

    const handlePhotoChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onloadend = async () => {
            const formDataForUpload = new FormData();
            formDataForUpload.append('file', file);
            formDataForUpload.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
        
            const response = await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, {
                method: 'POST',
                body: formDataForUpload,
            });
        
            const data = await response.json();
            const cloudinaryUrl = data.secure_url;
    
            setFormData({
                ...formData,
                photo: reader.result,
                photoUrl: cloudinaryUrl,
            });
        };
    
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleRemovePhoto = () => {
        setFormData({
            ...formData,
            photo: null,
        });
    };
    
    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const previousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const [isPage1Valid, setIsPage1Valid] = useState(false);
    const [isPage2Valid, setIsPage2Valid] = useState(false);
    const [isPage3Valid, setIsPage3Valid] = useState(false);
    const [isPage4Valid, setIsPage4Valid] = useState(false);
    const [isPage5Valid, setIsPage5Valid] = useState(false);
    useEffect(() => {
        const is1Valid = isValidFirstName && isValidLastName && isValidPronouns && isValidGender && isValidPhoneNumber;
        setIsPage1Valid(is1Valid);

        // const is2Valid = isValidLinkedin && isValidHighSchool && isValidHometownCity && isValidHometownState && isValidClassYear && isValidMajor;
        const is2Valid = isValidHighSchool && isValidHometownCity && isValidHometownState && isValidClassYear && isValidMajor;
        setIsPage2Valid(is2Valid);

        const is3Valid = isValidQuestionCampus && isValidQuestionClass && isValidQuestionExcite;
        setIsPage3Valid(is3Valid);

        const is4Valid = isValidQuestionClubs && isValidQuestionInterests && isValidQuestionMessage;
        setIsPage4Valid(is4Valid);

        const is5Valid = formData.photo != null && Array.isArray(selectedTags) && selectedTags.length === 4;
        setIsPage5Valid(is5Valid);
    }, [
        isValidFirstName,
        isValidLastName,
        isValidPronouns,
        isValidGender,
        isValidPhoneNumber,

        isValidLinkedin,
        isValidHighSchool,
        isValidHometownCity,
        isValidHometownState,
        isValidClassYear,
        isValidMajor,

        isValidQuestionCampus,
        isValidQuestionClass,
        isValidQuestionExcite,

        isValidQuestionClubs,
        isValidQuestionInterests,
        isValidQuestionMessage,

        formData.photo,
        selectedTags,
    ]);

    const navigate = useNavigate();
    const createProfilePage = () => {
        if (isPage5Valid) {
            navigate('/profiledisplay', { state: formData });
        }
    }

    const renderPage = () => {
        switch (currentPage) {
        case 0:
            return (
                <Page1
                    nextPage={nextPage}
                    formData={formData}
                    handleFirstNameChange={handleFirstNameChange}
                    handleLastNameChange={handleLastNameChange}
                    handlePronounsChange={handlePronounsChange}
                    handleGenderChange={handleGenderChange}
                    handleEmailChange={handleEmailChange}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    isValidFirstName={isValidFirstName}
                    isValidLastName={isValidLastName}
                    isValidPronouns={isValidPronouns}
                    isValidGender={isValidGender}
                    isValidEmail={isValidEmail}
                    isValidPhoneNumber={isValidPhoneNumber}
                    isPage1Valid={isPage1Valid}
                />
            );
        case 1:
            return (
                <Page2
                    nextPage={nextPage}
                    previousPage={previousPage}
                    formData={formData}
                    searchMajorOptions={searchMajorOptions}
                    handleLinkedinChange={handleLinkedinChange}
                    handleHighSchoolChange={handleHighSchoolChange}
                    handleHometownCityChange={handleHometownCityChange}
                    handleHometownStateChange={handleHometownStateChange}
                    handleClassYearChange={handleClassYearChange}
                    handleMajorChange={handleMajorChange}
                    isValidLinkedin={isValidLinkedin}
                    isValidHighSchool={isValidHighSchool}
                    isValidHometownCity={isValidHometownCity}
                    isValidHometownState={isValidHometownState}
                    isValidClassYear={isValidClassYear}
                    isValidMajor={isValidMajor}
                    isPage2Valid={isPage2Valid}
                />
            );
        case 2:
            return (
                <Page3
                    nextPage={nextPage}
                    previousPage={previousPage}
                    formData={formData}
                    handleQuestionCampusChange={handleQuestionCampusChange}
                    handleQuestionClassChange={handleQuestionClassChange}
                    handleQuestionExciteChange={handleQuestionExciteChange}
                    isValidQuestionCampus={isValidQuestionCampus}
                    isValidQuestionClass={isValidQuestionClass}
                    isValidQuestionExcite={isValidQuestionExcite}
                    isPage3Valid={isPage3Valid}
                />
            );
        case 3:
            return (
                <Page4
                    nextPage={nextPage}
                    previousPage={previousPage}
                    formData={formData}
                    handleQuestionClubsChange={handleQuestionClubsChange}
                    handleQuestionInterestsChange={handleQuestionInterestsChange}
                    handleQuestionMessageChange={handleQuestionMessageChange}
                    isValidQuestionClubs={isValidQuestionClubs}
                    isValidQuestionInterests={isValidQuestionInterests}
                    isValidQuestionMessage={isValidQuestionMessage}
                    isPage4Valid={isPage4Valid}
                />
            );
        case 4:
            return (
                <Page5
                    nextPage={nextPage}
                    previousPage={previousPage}
                    formData={formData}
                    searchTagOptions={searchTagOptions}
                    handlePhotoChange={handlePhotoChange}
                    handleRemovePhoto={handleRemovePhoto}
                    handleTagChange={handleTagChange}
                    selectedTags={selectedTags}
                    isPage5Valid={isPage5Valid}
                />
            );
        case 5:
            return (
                <Page6
                    previousPage={previousPage}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    searchMajorOptions={searchMajorOptions}
                    formData={formData}
                />
            );
        default:
            return null;
        }
    };

    return <div className="form">{renderPage()}</div>;
};


const Page1 = ({
                nextPage,
                formData,
                handleFirstNameChange,
                handleLastNameChange,
                handlePronounsChange,
                // handleAgeChange,
                handleGenderChange,
                handleEmailChange,
                handlePhoneNumberChange,
                isValidFirstName,
                isValidLastName,
                isValidPronouns,
                // isValidAge,
                isValidGender,
                isValidEmail,
                isValidPhoneNumber,
                isPage1Valid,
                }) => {
    return (
        <div className='tourguideonboard__entire-container'>
            <div className='tourguideonboard__left-side-container'>
                <div className='tourguideonboard__progress-bar-container'>
                    <div className='tourguideonboard__progress-bar-bars'>
                        <div className='tourguideonboard__page-tracker-lightbar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                    </div>
                    <p>1/5</p>
                </div>
                <div className='tourguideonboard__text-container'>
                    <welcome>welcome!</welcome>
                    <p>we're thrilled to have you</p>
                </div>
                <div className='tourguideonboard__input-and-bottom-container'>
                    <div className='tourguideonboard__input-container'>
                        <div className='tourguideonboard__input-container-first-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='firstNameInput'
                                    className='custom-floating-input'
                                    name='firstName'
                                    value={formData.firstName}
                                    maxLength={25}
                                    onChange={handleFirstNameChange}
                                    valid={isValidFirstName}
                                    invalid={!isValidFirstName}
                                    placeholder='first name'
                                />
                                <CFormLabel htmlFor='firstNameInput' className='cforminput__placeholder'>first name</CFormLabel>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='lastNameInput'
                                    className='custom-floating-input'
                                    name='lastName'
                                    value={formData.lastName}
                                    maxLength={25}
                                    onChange={handleLastNameChange}
                                    valid={isValidLastName}
                                    invalid={!isValidLastName}
                                    placeholder='last name'
                                />
                                <CFormLabel htmlFor='lastNameInput'>last name</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-second-row'>
                            {/* <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='ageInput'
                                    className='custom-floating-input'
                                    name='pronouns'
                                    value={formData.pronouns}
                                    maxLength={3}
                                    onChange={handleAgeChange}
                                    valid={isValidAge}
                                    invalid={!isValidAge}
                                    placeholder='pronouns'
                                />
                                <CFormLabel htmlFor='ageInput'>pronouns</CFormLabel>
                            </CFormFloating> */}
                            <CFormFloating className='mb-3'>
                                <CFormSelect
                                    className={isValidGender ? 'gender-valid-class' : 'gender-invalid-class'}
                                    floatingLabel="gender"
                                    id='genderInput'
                                    name='gender'
                                    value={formData.gender}
                                    onChange={handleGenderChange}
                                >
                                    <option>select gender</option>
                                    <option>male</option>
                                    <option>female</option>
                                    <option>other</option>
                                </CFormSelect>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormSelect
                                    className={isValidPronouns ? 'pronouns-valid-class' : 'pronouns-invalid-class'}
                                    floatingLabel="pronouns"
                                    id='pronounsInput'
                                    name='pronouns'
                                    value={formData.pronouns}
                                    onChange={handlePronounsChange}
                                >
                                    <option>select pronouns</option>
                                    <option>he/him</option>
                                    <option>she/her</option>
                                    <option>they/them</option>
                                    <option>he/they</option>
                                    <option>she/they</option>
                                </CFormSelect>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-third-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='emailInput'
                                    className='custom-floating-input'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleEmailChange}
                                    valid={true}
                                    // invalid={!isValidEmail}
                                    placeholder='email'
                                    disabled='true'
                                />
                                <CFormLabel htmlFor='emailInput'>email</CFormLabel>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='phoneNumberInput'
                                    className='custom-floating-input'
                                    name='phoneNumber'
                                    value={formData.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    valid={isValidPhoneNumber}
                                    invalid={!isValidPhoneNumber}
                                    placeholder='phone number'
                                />
                                <CFormLabel htmlFor='phoneNumberInput'>phone number</CFormLabel>
                            </CFormFloating>
                        </div>
                    </div>
                    <div className='tourguideonboard__arrow-container'>
                        <div style={{visibility: 'hidden'}}><button><img src={leftarrow} alt='left arrow' onClick={nextPage} /></button></div>
                        <button disabled={!isPage1Valid} onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button>
                        {/* <button onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button> */}
                    </div>
                </div>
            </div>
            <div className='tourguideonboard__right-side-container'>
                <img src={islandman} alt='sitting dude'/>
                <div className='tourguideonboard__information-text-container'>
                    <h2>we are ember</h2>
                    {/* <p>you're joining a community of guides that is committed </p> */}
                </div>
            </div>
        </div>
    )
}

const Page2 = ({
                nextPage,
                previousPage,
                formData,
                searchMajorOptions,
                handleLinkedinChange,
                handleHighSchoolChange,
                handleHometownCityChange,
                handleHometownStateChange,
                handleClassYearChange,
                handleMajorChange,
                isValidLinkedin,
                isValidHighSchool,
                isValidHometownCity,
                isValidHometownState,
                isValidClassYear,
                isValidMajor,
                isPage2Valid,
                }) => {

    return (
        <div className='tourguideonboard__entire-container'>
            <div className='tourguideonboard__left-side-container'>
                <div className='tourguideonboard__progress-bar-container'>
                    <div className='tourguideonboard__progress-bar-bars'>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-lightbar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                    </div>
                    <p>2/5</p>
                </div>
                <div className='tourguideonboard__text-container'>
                    <welcome>welcome!</welcome>
                    <p>we're thrilled to have you</p>
                </div>
                <div className='tourguideonboard__input-and-bottom-container'>
                    <div className='tourguideonboard__input-container'>
                        <div className='tourguideonboard__input-container-first-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='linkedinInput'
                                    className='custom-floating-input'
                                    name='linkedin'
                                    value={formData.linkedin}
                                    onChange={handleLinkedinChange}
                                    valid={isValidLinkedin}
                                    invalid={!isValidLinkedin}
                                    placeholder='linkedin'
                                />
                                <CFormLabel htmlFor='linkedinInput'>linkedin (optional)</CFormLabel>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='highSchoolInput'
                                    className='custom-floating-input'
                                    name='highSchool'
                                    value={formData.highSchool}
                                    maxLength={40}
                                    onChange={handleHighSchoolChange}
                                    valid={isValidHighSchool}
                                    invalid={!isValidHighSchool}
                                    placeholder='high school'
                                />
                                <CFormLabel htmlFor='highSchoolInput'>high school</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-second-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='hometownCityInput'
                                    className='custom-floating-input'
                                    name='hometownCity'
                                    value={formData.hometownCity}
                                    maxLength={30}
                                    onChange={handleHometownCityChange}
                                    valid={isValidHometownCity}
                                    invalid={!isValidHometownCity}
                                    placeholder='hometown city'
                                />
                                <CFormLabel htmlFor='hometownCityInput'>hometown city</CFormLabel>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormSelect
                                    className={isValidHometownState ? 'hometownState-valid-class' : 'hometownState-invalid-class'}
                                    floatingLabel="hometown state"
                                    id='hometownStateInput'
                                    name='hometownState'
                                    value={formData.hometownState}
                                    onChange={handleHometownStateChange}
                                >
                                    <option>select state</option>
                                    <option>alabama</option>
                                    <option>alaska</option>
                                    <option>arizona</option>
                                    <option>arkansas</option>
                                    <option>california</option>
                                    <option>colorado</option>
                                    <option>connecticut</option>
                                    <option>delaware</option>
                                    <option>florida</option>
                                    <option>georgia</option>
                                    <option>hawaii</option>
                                    <option>idaho</option>
                                    <option>illinois</option>
                                    <option>iowa</option>
                                    <option>kansas</option>
                                    <option>kentucky</option>
                                    <option>louisiana</option>
                                    <option>maine</option>
                                    <option>maryland</option>
                                    <option>massachusetts</option>
                                    <option>michigan</option>
                                    <option>minnesota</option>
                                    <option>mississippi</option>
                                    <option>missouri</option>
                                    <option>montana</option>
                                    <option>nebraska</option>
                                    <option>nevada</option>
                                    <option>new hampshire</option>
                                    <option>new jersey</option>
                                    <option>new mexico</option>
                                    <option>new york</option>
                                    <option>north carolina</option>
                                    <option>north dakota</option>
                                    <option>ohio</option>
                                    <option>oklahoma</option>
                                    <option>oregon</option>
                                    <option>pennsylvania</option>
                                    <option>rhode island</option>
                                    <option>south carolina</option>
                                    <option>south dakota</option>
                                    <option>tennessee</option>
                                    <option>texas</option>
                                    <option>utah</option>
                                    <option>vermont</option>
                                    <option>virginia</option>
                                    <option>washington</option>
                                    <option>west virginia</option>
                                    <option>wisconsin</option>
                                    <option>wyoming</option>
                                </CFormSelect>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-third-row'>
                            <CFormFloating className='mb-3'>
                                <CFormSelect
                                    className={isValidClassYear ? 'classYear-valid-class' : 'classYear-invalid-class'}
                                    floatingLabel="class year"
                                    id='classYearInput'
                                    name='classYear'
                                    value={formData.classYear}
                                    onChange={handleClassYearChange}
                                >
                                    <option>select class year</option>
                                    <option>freshman</option>
                                    <option>sophomore</option>
                                    <option>junior</option>
                                    <option>senior</option>
                                </CFormSelect>
                            </CFormFloating>
                            <CFormFloating className='mb-3'>
                                <CFormSelect
                                    className={isValidMajor ? 'major-valid-class' : 'major-invalid-class'}
                                    floatingLabel="major"
                                    id='majorInput'
                                    name='major'
                                    value={formData.major}
                                    onChange={handleMajorChange}
                                >
                                    {searchMajorOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.name}</option>
                                    ))}
                                </CFormSelect>
                            </CFormFloating>
                        </div>
                    </div>
                    <div className='tourguideonboard__arrow-container'>
                        <button><img src={leftarrow} alt='left arrow' onClick={previousPage} /></button>
                        <button disabled={!isPage2Valid} onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button>
                        {/* <button onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button> */}
                    </div>
                </div>
            </div>
            <div className='tourguideonboard__right-side-container'>
                <img src={islandman} alt='sitting dude'/>
                <div className='tourguideonboard__information-text-container'>
                    <h2>we are ember</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed enim ut sem viverra aliquet eget sit amet tellus. Urna molestie at elementum eu facilisis sed. Nibh mauris cursus mattis molestie.</p> */}
                </div>
            </div>
        </div>
    )
}

const Page3 = ({
                nextPage,
                previousPage,
                formData,
                handleQuestionCampusChange,
                handleQuestionClassChange,
                handleQuestionExciteChange,
                isValidQuestionCampus,
                isValidQuestionClass,
                isValidQuestionExcite,
                isPage3Valid,
                }) => {

    return (
        <div className='tourguideonboard__entire-container'>
            <div className='tourguideonboard__left-side-container'>
                <div className='tourguideonboard__progress-bar-container'>
                    <div className='tourguideonboard__progress-bar-bars'>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-lightbar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                    </div>
                    <p>3/5</p>
                </div>
                <div className='tourguideonboard__text-container'>
                    <welcome>welcome!</welcome>
                    <p>we're thrilled to have you</p>
                    <p style={{ fontSize: 'clamp(9px, .8vw, 17px)' }}>high school students will see these answers!</p>
                </div>
                <div className='tourguideonboard__input-and-bottom-container'>
                    <div className='tourguideonboard__input-container'>
                        <div className='tourguideonboard__input-container-first-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='questionCampusInput'
                                    className='custom-floating-input-long'
                                    name='questionCampus'
                                    value={formData.questionCampus}
                                    maxLength={120}
                                    onChange={handleQuestionCampusChange}
                                    valid={isValidQuestionCampus}
                                    invalid={!isValidQuestionCampus}
                                    placeholder='campus question'
                                />
                                <CFormLabel htmlFor='questionCampusInput'>favorite thing to do on campus</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-second-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='questionClassInput'
                                    className='custom-floating-input-long'
                                    name='questionClass'
                                    value={formData.questionClass}
                                    maxLength={120}
                                    onChange={handleQuestionClassChange}
                                    valid={isValidQuestionClass}
                                    invalid={!isValidQuestionClass}
                                    placeholder='class question'
                                />
                                <CFormLabel htmlFor='questionClassInput'>favorite class (with small description)</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-third-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='questionExciteInput'
                                    className='custom-floating-input-long'
                                    name='questionExcite'
                                    value={formData.questionExcite}
                                    maxLength={120}
                                    onChange={handleQuestionExciteChange}
                                    valid={isValidQuestionExcite}
                                    invalid={!isValidQuestionExcite}
                                    placeholder='excite question'
                                />
                                <CFormLabel htmlFor='questionExciteInput'>most exciting part about campus</CFormLabel>
                            </CFormFloating>
                        </div>
                    </div>
                    <div className='tourguideonboard__arrow-container'>
                        <button><img src={leftarrow} alt='left arrow' onClick={previousPage} /></button>
                        <button disabled={!isPage3Valid} onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button>
                        {/* <button onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button> */}
                    </div>
                </div>
            </div>
            <div className='tourguideonboard__right-side-container'>
                <img src={islandman} alt='sitting dude'/>
                <div className='tourguideonboard__information-text-container'>
                    <h2>we are ember</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed enim ut sem viverra aliquet eget sit amet tellus. Urna molestie at elementum eu facilisis sed. Nibh mauris cursus mattis molestie.</p> */}
                </div>
            </div>
        </div>
    )
}

const Page4 = ({
                nextPage,
                previousPage,
                formData,
                handleQuestionClubsChange,
                handleQuestionInterestsChange,
                handleQuestionMessageChange,
                isValidQuestionClubs,
                isValidQuestionInterests,
                isValidQuestionMessage,
                isPage4Valid,
                }) => {
    return (
        <div className='tourguideonboard__entire-container'>
            <div className='tourguideonboard__left-side-container'>
                <div className='tourguideonboard__progress-bar-container'>
                    <div className='tourguideonboard__progress-bar-bars'>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-lightbar'></div>
                        <div className='tourguideonboard__page-tracker-greybar'></div>
                    </div>
                    <p>4/5</p>
                </div>
                <div className='tourguideonboard__text-container'>
                    <welcome>welcome!</welcome>
                    <p>we're thrilled to have you</p>
                    <p style={{ fontSize: 'clamp(9px, .8vw, 17px)' }}>high school students will see these answers!</p>
                </div>
                <div className='tourguideonboard__input-and-bottom-container'>
                    <div className='tourguideonboard__input-container'>
                        <div className='tourguideonboard__input-container-first-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput 
                                    type='text'
                                    id='questionClubsInput'
                                    className='custom-floating-input-long'
                                    name='questionClubs'
                                    value={formData.questionClubs}
                                    maxLength={120}
                                    onChange={handleQuestionClubsChange}
                                    valid={isValidQuestionClubs}
                                    invalid={!isValidQuestionClubs}
                                    placeholder='clubs question'
                                />
                                <CFormLabel htmlFor='questionClubsInput'>clubs/organizations you're a part of</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-second-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='questionInterestsInput'
                                    className='custom-floating-input-long'
                                    name='questionInterests'
                                    value={formData.questionInterests}
                                    maxLength={120}
                                    onChange={handleQuestionInterestsChange}
                                    valid={isValidQuestionInterests}
                                    invalid={!isValidQuestionInterests}
                                    placeholder='interests question'
                                />
                                <CFormLabel htmlFor='questionInterestsInput'>general interests</CFormLabel>
                            </CFormFloating>
                        </div>
                        <div className='tourguideonboard__input-container-third-row'>
                            <CFormFloating className='mb-3'>
                                <CFormInput
                                    type='text'
                                    id='questionMessageInput'
                                    className='custom-floating-input-long'
                                    name='questionMessage'
                                    value={formData.questionMessage}
                                    maxLength={120}
                                    onChange={handleQuestionMessageChange}
                                    valid={isValidQuestionMessage}
                                    invalid={!isValidQuestionMessage}
                                    placeholder='excite question'
                                />
                                <CFormLabel htmlFor='questionMessageInput'>a message to your future high schoolers (i.e. can't wait to meet you!)</CFormLabel>
                            </CFormFloating>
                        </div>
                    </div>
                    <div className='tourguideonboard__arrow-container'>
                        <button><img src={leftarrow} alt='left arrow' onClick={previousPage} /></button>
                        <button disabled={!isPage4Valid} onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button>
                        {/* <button onClick={nextPage} ><img src={rightarrow} alt='right arrow'/></button> */}
                    </div>
                </div>
            </div>
            <div className='tourguideonboard__right-side-container'>
                <img src={islandman} alt='sitting dude'/>
                <div className='tourguideonboard__information-text-container'>
                    <h2>we are ember</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed enim ut sem viverra aliquet eget sit amet tellus. Urna molestie at elementum eu facilisis sed. Nibh mauris cursus mattis molestie.</p> */}
                </div>
            </div>
        </div>
    )
}

const Page5 = ({
                nextPage,
                previousPage,
                formData,
                searchTagOptions,
                handlePhotoChange,
                handleRemovePhoto,
                handleTagChange,
                selectedTags,
                isPage5Valid,
                }) => {
    const fileInputRef = useRef(null);

    return (
        <div className='tourguideonboard__entire-container'>
            <div className='tourguideonboard__left-side-container'>
                <div className='tourguideonboard__progress-bar-container'>
                    <div className='tourguideonboard__progress-bar-bars'>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-darkbar'></div>
                        <div className='tourguideonboard__page-tracker-lightbar'></div>
                    </div>
                    <p>5/5</p>
                </div>
                <div className='tourguideonboard__text-container'>
                    <welcome>smile!</welcome>
                    <p>upload a picture of yourself</p>
                    <p style={{ fontSize: 'clamp(9px, .8vw, 17px)' }}>and then select your tags!</p>
                </div>
                <div className='tourguideonboard__photo-input-entire-container'>
                    <div className='tourguideonboard__pfp-upload-container'>
                        {
                            !formData.photo ? 
                            (
                                <div className="tourguideononboard__image-upload-label" onClick={() => fileInputRef.current.click()}>
                                    {/* <button>upload photo of yourself!</button> */}
                                    <img className='tourguideonboard__photographer-button' src={photographer} alt='photographer' />
                                    {/* <img src={null} alt='hello'/> */}
                                    <input
                                        id="upload-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={handlePhotoChange}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            )
                            :
                            (
                                <div className="tourguideonboard__photo-preview">
                                    <img src={formData.photoUrl} alt="photo" />
                                    {/* <img src={formData.photo} alt="Preview" /> */}
                                    <button className="tourguideonboard__rechoose-button" onClick={handleRemovePhoto}>rechoose photo</button>
                                </div>
                            )
                        }
                    </div>
                    <div className='tourguideonboard__arrow-container'>
                        <button><img src={leftarrow} alt='left arrow' onClick={previousPage}/></button>
                        <button disabled={!isPage5Valid} onClick={nextPage}><img src={rightarrow} alt='left arrow'/></button>
                        {/* <button onClick={nextPage}><img src={rightarrow} alt='left arrow'/></button> */}
                    </div>
                </div>
            </div>
            <div className='tourguideonboard__right-side-container'>
                <img src={islandman} alt='sitting dude'/>
                <div className='tourguideonboard__information-text-container'>
                    <div>
                        <h2 style={{ fontSize: "2rem", paddingBottom: "1px" }}>choose the 4 tags that represent you the best</h2>
                        <p style={{ fontSize: ".8vw", paddingBottom: "30px" }}>(students can search for you based on these tags)</p>
                    </div>
                    <div className='tourguideonboard__tags-container'>
                        {searchTagOptions.map(tag => {
                            return (
                                <Customcheckbox
                                    key={tag.value}
                                    labelName={tag.name}
                                    // isSelected={selectedTags.includes(tag.value)}
                                    isSelected={Array.isArray(selectedTags) && selectedTags.includes(tag.value)}
                                    onCheckboxChange={() => handleTagChange(tag.value)}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

const Page6 = ({
                previousPage,
                handleSubmit,
                loading,
                formData,
                searchMajorOptions,
                }) => {

    const selectedMajorName = searchMajorOptions.find(option => option.value === formData.major)?.name;
    
    function getUniversityName(email) {
        if (email.endsWith('@stanford.edu')) {
            return "stanford university";
        } else if (email.endsWith('@berkeley.edu')) {
            return "uc berkeley";
        }
    };

    return (
        <div className='tourguideonboard__confirm-profile-container'>
            {
                loading ?
                <div className='loadingspinner'></div> :
                <Profile_Display_Page
                    profileUniversity={getUniversityName(formData.email)}
                    profilePhoto={formData.photo}
                    profileFirstName={formData.firstName}
                    profileLastName={formData.lastName}
                    profileMajor={selectedMajorName}
                    profileClass={formData.classYear}
                    profileGender={formData.gender}
                    profileLinkedin={formData.linkedin}
                    profileCity={formData.hometownCity}
                    profileState={formData.hometownState}
                    profileHighSchool={formData.highSchool}
                    profileCampusQuestion={formData.questionCampus}
                    profileClassQuestion={formData.questionClass}
                    profileExciteQuestion={formData.questionExcite}
                    profileClubsQuestion={formData.questionClubs}
                    profileInterestsQuestion={formData.questionInterests}
                    profileMessageQuestion={formData.questionMessage}

                    profileEndButton1OnclickAction={previousPage}
                    profileEndButton1Visibility={'show'}
                    profileEndButton1Label={"something's off"}
                    
                    profileEndButton2OnclickAction={null}
                    profileEndButton2Visibility={'hidden'}
                    profileEndButton2Label={null}

                    profileEndButton3OnclickAction={handleSubmit}
                    profileEndButton3Visibility={'show'}
                    profileEndButton3Label={"looks great!"}
                />
            }
        </div>
    )   
}

export default Tour_Guide_Onboard