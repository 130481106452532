// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// const ProtectedRoute = ({ children }) => {
//     const { currentUser } = useAuth();

//     if (currentUser) {
//         return children;
//     }

//     return <Navigate to="/newsignup" replace />;
// }

// export default ProtectedRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const isGuide = (email) => {
    return email.endsWith('@stanford.edu') || email.endsWith('@berkeley.edu');
};

const ProtectedRoute = ({ children, role }) => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/newsignup" replace />;
    }

    // guide only route but user is student
    if (role === 'guide' && !isGuide(currentUser.email)) {
        return <Navigate to="/newsearch" replace />;
    }

    // student only route but user is guide
    if (role === 'student' && isGuide(currentUser.email)) {
        return <Navigate to="/" replace />;
    }

    return children;
}

export default ProtectedRoute;