import * as React from 'react';
import { useEffect, useState } from 'react';
import confetti1 from '../../assets/confetti-left.png';
import confetti2 from '../../assets/confetti-right.png';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './pricing_page.css';

function Pricing_Page() {
    const [currentEmail, setCurrentEmail] = useState('');
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentEmail(user.email);
            } else {
                // no user is signed in currently
                window.location.href = '/newsignin';
            }
        });
        return () => unsubscribe();
    }, []);

    return (
        <div className='pricingpage__entire-container'>
            <div
                className='pricingpage__top-area-container'
                style={{ borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px' }}
            >
                <h1 style={{ padding: '0 0' }}>make your selection below:</h1>
                <stripe-pricing-table
                    pricing-table-id={process.env.REACT_APP_STRIPE_UPDATED_PRICING_TABLE_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-email={currentEmail}
                >
                </stripe-pricing-table>
            </div>
            <div className='pricingpage__bottom-area-container'>
                <img src={confetti1} />
                <p>after you finish the payment process, we'll begin scheduling your experience!</p>
                <img src={confetti2} />
            </div>
        </div>
    );
}

export default Pricing_Page;