import React from 'react';
import { tagColors, tagDictionary } from '../../../../../../info/searchDictionaries';

const ProfileTag = ( { tag } ) => {
    const profileTagBackgroundColor = tagColors[tagDictionary[tag]] || 'rgba(128, 128, 128, 0.6)';

    const profileTagStyle = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: profileTagBackgroundColor,
        paddingLeft: '3px',
        paddingRight: '3px',
        paddingTop: '2px',
        paddingBottom: '2px',
        borderRadius: '15px',
        fontSize: 'clamp(18px, 1.3vw, 24px)',
        width: 'clamp(37.5px, 2.7vw, 50px)',
        height: 'clamp(30px, 2.16vw, 40px)',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <div style={profileTagStyle}>{tagDictionary[tag]}</div>
    )
};

export default ProfileTag;