import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../services/firebase';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // To keep track of the initial loading state

    useEffect(() => {
        // This will listen for authentication state changes.
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        });

        // Cleanup the listener on component unmount.
        return unsubscribe;
    }, []);

    const contextValue = {
        currentUser,
        setCurrentUser, // Generally, it's not a common practice to expose setters directly. Consider using functions that handle specific tasks, e.g., signup, signin, signout.
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {!loading && children} {/* Render children only after we've determined the auth state */}
        </AuthContext.Provider>
    );
};
