import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Squareprofilecard } from './components';
import Squarebox from './components/squarebox/Squarebox';
import FormContainer from './components/FormContainer';
import Modal from '../search_page/components/modal/Modal';
import { searchMajorOptions, searchTagOptions, searchLocationOptions } from '../../info/searchDictionaries';
import b1 from '../../assets/search_pfps/b/b1.svg';
import g1 from '../../assets/search_pfps/g/g1.svg';
import nb1 from '../../assets/search_pfps/nb/nb1.svg';

import './newsearch_page.css';

const Newsearch_Page = () => {
    const navigate = useNavigate();

    const [selectedMajors, setSelectedMajors] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleMajorSelection = selectedOptions => {
        setSelectedMajors(selectedOptions);
    };
    useEffect(() => {
        setSelectedMajors(selectedMajors);
    }, [selectedMajors]);

    const handleLocationSelection = selectedOptions => {
        setSelectedLocations(selectedOptions);
    };
    useEffect(() => {
        setSelectedLocations(selectedLocations);
    }, [selectedLocations]);

    const handleTagSelection = selectedOptions => {
        setSelectedTags(selectedOptions);
    };
    useEffect(() => {
        setSelectedTags(selectedTags);
    }, [selectedTags]);

    function getDisplayNameFromValue(value) {
        const tagOption = searchTagOptions.find(option => option.value === value);
        if (tagOption) {
            return tagOption.name.split(" ")[0];
        }
        return null;
    };

    const [allUsers, setAllUsers] = useState([]);
    useEffect(() => {
        async function fetchAllUsers() {
            try {
                const response = await fetch("/api/fetch-all-users");
                const data = await response.json();
                setAllUsers(data);
            } catch (error) {
                console.error("There was an error fetching all users:", error);
            }
        }

        fetchAllUsers();
    }, []);

    const getUserKey = (user) => {
        return user.firstName + user.lastName + user.major + user.classYear + user.hometownCity + user.hometownState;
    };

    const [users, setUsers] = useState([]);
    useEffect(() => {
        async function fetchFilteredUsers() {
            if (selectedMajors.length || selectedTags.length || selectedLocations.length) {
                try {
                    const response = await fetch("/api/filter-users", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            allUsers,
                            selectedMajors,
                            selectedTags,
                            selectedLocations
                        })
                    });
                    const data = await response.json();
                    setUsers(data);
                } catch (error) {
                    console.error("There was an error filtering the users:", error);
                }
            } else {
                setUsers([]);
            }
        }
        
        fetchFilteredUsers();
    }, [selectedMajors, selectedTags, selectedLocations]);

    const pfpImages = {
        b: [b1],
        g: [g1],
        nb: [nb1],
    };

    function getRandomImage(folder) {
        const randomIndex = Math.floor(Math.random() * pfpImages[folder].length);
        return pfpImages[folder][randomIndex];
    };

    function getPronounFolder(pronouns) {
        if (pronouns === "he/him") {
            return 'b';
        } else if (pronouns === "she/her") {
            return 'g';
        } else {
            return 'nb';
        }
    };

    const [showUserModal, setShowUserModal] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const openUserModal = (user) => {
        setShowUserModal(user);
        setOpenModal(true);
    };

    const [isHovered, setIsHovered] = useState(false);
    const baseStyle = {
        border: 'none',
        borderStyle: 'none',
        padding: '5px 10px',
        borderRadius: '25px',
        backgroundColor: 'var(--color-orange)',
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'var(--font-poppins)',
        cursor: 'pointer',
        fontSize: 'clamp(20px, 1vw, 30px)',
        transition: 'transform 0.3s',
    };
    const hoveredStyle = {
        ...baseStyle,
        transform: 'scale(1.2)'
    };

    return (
        <div className='newsearch__entire-container'>
            <div className='newsearch__top-container'>
                <div className='newsearch__criteria-header-container'>
                    <p>begin your search by filtering on major, tag, or home state below</p>
                </div>
                <div className='newsearch__header-container'>
                    <h1>this is where the magic happens</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'visible'}}>
                        <p>our algorithm gives you the best 6 matches sorted by how well they fit your criteria</p>
                        <button 
                            style={isHovered ? hoveredStyle : baseStyle}
                            onClick={() => {
                                if (Array.isArray(users) && users.length > 0) {
                                    navigate('/confirm', { state: { users } });
                                }
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {Array.isArray(users) && users.length > 0 && "i'm ready to book!"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='newsearch__bottom-container'>
                <div className='newsearch__left-side-container'>
                    <div className='newsearch__search-criteria-area'>
                        <FormContainer
                            options={searchMajorOptions.slice(1)}
                            titleLabel="filter by major"
                            label="click here to select majors"
                            selectedOptions={selectedMajors}
                            onSelectionChange={handleMajorSelection}
                        />

                        <FormContainer
                            options={searchTagOptions}
                            titleLabel="filter by tag"
                            label="click here to select tags"
                            selectedOptions={selectedTags}
                            onSelectionChange={handleTagSelection}
                        />

                        <FormContainer
                            options={searchLocationOptions}
                            titleLabel="filter by home state"
                            label="click here to select states"
                            selectedOptions={selectedLocations}
                            onSelectionChange={handleLocationSelection}
                        />
                    </div>
                    <p style={{}}>
                        {Array.isArray(users) && users.length > 0 && "why anonymize? we protect the information of our guides; then, once the experience is confirmed, you see everything :)"}
                    </p>
                </div>
                <div className='newsearch__right-side-container'>
                    <div className='newsearch__search-results-container'>
                        {
                            openModal 
                            &&
                            <Modal
                                closeModal={() => setOpenModal(false)}
                                profilePhoto={getRandomImage(getPronounFolder(showUserModal.pronouns))}
                                firstName={showUserModal.firstName}
                                lastName={showUserModal.lastName}
                                major={searchMajorOptions.find(option => option.value === showUserModal.major)?.name}
                                classYear={showUserModal.classYear}
                                gender={showUserModal.gender}
                                linkedin={showUserModal.linkedin}
                                city={showUserModal.hometownCity}
                                state={showUserModal.hometownState}
                                highSchool={showUserModal.highSchool}
                                campusQuestion={showUserModal.questionCampus}
                                classQuestion={showUserModal.questionClass}
                                exciteQuestion={showUserModal.questionExcite}
                                clubsQuestion={showUserModal.questionClubs}
                                interestsQuestion={showUserModal.questionInterests}
                                messageQuestion={showUserModal.questionMessage}
                            />
                        }
                        {
                            (!Array.isArray(users) || users.length === 0)
                            ? (
                                <>
                                    <Squarebox rankNumber={"rank #1"} />
                                    <Squarebox rankNumber={"rank #2"} />
                                    <Squarebox rankNumber={"rank #3"} />
                                    <Squarebox rankNumber={"rank #4"} />
                                    <Squarebox rankNumber={"rank #5"} />
                                    <Squarebox rankNumber={"rank #6"} />
                                </>
                            )
                            : users.map(user => (
                                <Squareprofilecard
                                    key={getUserKey(user)}
                                    profilecardImage={getRandomImage(getPronounFolder(user.pronouns))}
                                    profilecardTag1={getDisplayNameFromValue(user.selectedTags[0])}
                                    profilecardTag2={getDisplayNameFromValue(user.selectedTags[1])}
                                    profilecardTag3={getDisplayNameFromValue(user.selectedTags[2])}
                                    profilecardTag4={getDisplayNameFromValue(user.selectedTags[3])}
                                    profilecardPronouns={user.pronouns}
                                    profilecardName={user.firstName + " " + user.lastName}
                                    profilecardMajor={searchMajorOptions.find(option => option.value === user.major)?.name}
                                    profilecardYear={user.classYear}
                                    profilecardHometown={user.hometownCity + ", " + user.hometownState}
                                    onClick={() => openUserModal(user)}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsearch_Page