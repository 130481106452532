// import React, { useState } from 'react';
// import { FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
// import './customcheckbox.css';

// const Customcheckbox = ( { labelName } ) => {
//     const labelStyle = {
//         fontFamily: 'Manrope, sans-serif',
//         fontSize: '15px',
//         fontWeight: '700',
//       };
    
//       // State to keep track of the number of checkboxes selected
//       const [numSelected, setNumSelected] = useState(0);
    
//       // Function to handle checkbox change
//       const handleCheckboxChange = (event) => {
//         const isChecked = event.target.checked;
//         setNumSelected((prevNum) => prevNum + (isChecked ? 1 : -1));
//       };

//       console.log("Num selected: " + numSelected);
    
//     return (
//         <FormGroup>
//             <FormControlLabel
//               className='guideonboard__formcontrollabel-style'
//               control={
//                 <Checkbox
//                     onChange={handleCheckboxChange}
//                     disabled={numSelected >= 5}
//                 />
//               }
//               label={<Typography style={labelStyle}>{labelName}</Typography>}
//             />
//         </FormGroup>
//     );
// }

// export default Customcheckbox


import React from 'react';
import './customcheckbox.css';

const CustomCheckbox = ({ labelName, isSelected, onCheckboxChange }) => (
  <label className="tgo-custom-checkbox-label">
    <input
      type="checkbox"
      className="tgo-custom-checkbox-input"
      checked={isSelected}
      onChange={onCheckboxChange}
    />
    <span className={`tgo-custom-checkbox-text ${isSelected ? 'bold-text' : ''}`}>
      {labelName}
    </span>
  </label>
);

export default CustomCheckbox;