import React, { useEffect } from 'react';
import Photoloop from '../../components/photoloop/Photoloop';
// import riya from '../../../../assets/hi5.png';
import riya from '../../../../assets/manandwoman.png';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';
import './newtoppart.css';

const Newtoppart = () => {
    useEffect(() => {
        const tsyped = new Typed(".auto-type", {
            strings: [
                "personalized",
                "interactive",
                "candid",
                "1-on-1",
                "customizable",
                "authentic",
            ],
            // smaller speed is faster
            typeSpeed: 50,
            backSpeed: 35,
            startDelay: 1000,
            backDelay: 2000,
            loop: true,
        });

        return () => {
            tsyped.destroy();
        };
    }, []);

    return (
        <div className='newtoppart__total-entire-container'>
            <div className='newtoppart__entire-container'>
                <div className='newtoppart__main-text'>
                    <h1>discover your dream college through <br /> <span className='auto-type'></span><br /> experiences with current <br /> university students</h1>

                    <img src={riya} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "visible" }}>
                    <div className='newtoppart__submessage'>
                        {/* your college <text style={{ color: "var(--color-orange)", fontFamily: "var(--font-satisfy)" }}>tour</text> is waiting */}
                        {/* your college tour is waiting */}
                        we're ready for you
                    </div>
                    <div className='newtoppart__join-us-button'>
                        <Link to='/newsignup'><button className='newtoppart__joinus'>join us</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newtoppart