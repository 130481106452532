import React from 'react';
import './landing_page.css';
import { Colleges, CTA, Navbar, Testimonials } from './components';
import alara from '../../assets/alara.jpeg';
// import alara from '../../assets/alara.jpeg';
import avni from '../../assets/avni-circle.png';
import Photoloop from './components/photoloop/Photoloop';
import { Footer, Guides, Header, Reasons, Steps, Whyus, Newheader } from './containers';
import Newtoppart from './containers/newtoppart/Newtoppart';
import Tokenperson from './containers/tokenperson/Tokenperson';
import Mostnewsteps from '../mostnewsteps/Mostnewsteps';
import Newwhyus from './containers/newwhyus/Newwhyus';
import Landingpfpcontainer from './containers/landingpfpcontainer/Landingpfpcontainer';
import Newguidedisplay from './containers/newguidedisplay/Newguidedisplay';
import Pricingsection from './components/pricingsection/Pricingsection';

import { Parallax, ParallaxLayer } from '@react-spring/parallax';

const Landing_Page = () => {
    return (
        <div className='Landing_Page'>
            <Navbar />


{/* 

            <Parallax pages={2}>
                <ParallaxLayer offset={0}>
                    <div>hello</div>
                </ParallaxLayer>
                <ParallaxLayer offset={1}>
                    <div>dawg</div>
                </ParallaxLayer>
            </Parallax> */}


            <div id='home' style={{backgroundColor: "#dfedec"}}><Newtoppart /></div>

            {/* <Tokenperson /> */}
            
            <div id='why' style={{backgroundColor: "#26153a"}}><Newwhyus /></div>


            <div id='process' style={{backgroundColor: "#dfedec"}}><Mostnewsteps /></div>

            
            {/* make sure to add the profile display page component */}
            {/* add something about the 5 senses */}
            <div id='homepage__pfp-display' style={{backgroundColor: "#26153a"}}><Landingpfpcontainer /></div>
            {/* <div id="home"><Header /></div> */}
            {/* <Colleges /> */}
            {/* <div id="guides"><Guides /></div> */}
            {/* <div id="process"><Steps /></div> */}
            {/* <Testimonials /> */}
            {/* <Reasons /> */}
            {/* <div id="home"><Newheader /></div> */}
            {/* <div id="whyus"><Whyus /></div> */}
            <div id='guides'><Newguidedisplay /></div>
            <Tokenperson />
            <Photoloop />
            <div id='pricing' style={{backgroundColor: "#dfedec"}}><Pricingsection /></div>
            <CTA />
            <div id='footer' style={{backgroundColor: "#dfedec"}}><Footer /></div>
            {/* <div id='pricing' style={{backgroundColor: "#26153a"}}><Pricingsection /></div> */}
        </div>
    )
}

export default Landing_Page