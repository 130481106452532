import React from 'react';
import './cta.css';
import { Link } from 'react-router-dom';

const CTA = () => (
  <div className='tours__cta-entire-container'>
    <div className="tours__cta">
      <div className="tours__cta-content">
        <p>with over 100 guides, ember is ready for you</p>
        <h3>join us to witness a reinvented admissions process</h3>
      </div>
      <div className="tours__cta-btn">
        <Link to='/newsignup'><button type="button" className='newtoppart__joinus-button'>get started</button></Link>
      </div>
    </div>
  </div>
);

export default CTA;