import React from "react";
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/firebase';
import confetti1 from '../../assets/confetti-left.png';
import confetti2 from '../../assets/confetti-right.png';
import './booking_confirmation_page.css';

const Booking_Confirmation_Page = () => {
    const [currentEmail, setCurrentEmail] = useState('');
    const [confirmedUserData, setConfirmedUserData] = useState([]);
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentEmail(user.email);
            } else {
                // no user is signed in currently
                window.location.href = '/newsignin';
            }
        });
        return () => unsubscribe();
    }, []);

    const handleSignOutClick = async () => {
        try {
            await auth.signOut();
            // window.location.href = "http://localhost:3000";
            window.location.href = "https://www.tourember.com";
        } catch (error) {
            console.error("error signing out:", error);
        }
    };

    return (
        <div className="guidedone__entire-container">
            <div className="guidedone__top-area-container">
                <div className="guidedone__top-area-text-container">
                    <h1special>you're good to go!</h1special>
                </div>
                <div className="guidedone__top-area-buttons-container">
                    <div className="guidedone__buttons-button" onClick={handleSignOutClick}>
                        <span>sign out</span>
                    </div>
                </div>
                <div>
                    <p
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            textAlign: 'center',
                            justifyContent: 'center',
                            fontWeight: 700,
                            padding: '20px 50px',
                            fontSize: 'clamp(10px, 2vw, 20px)',
                            color: 'white',
                            marginBottom: '0px',
                        }}
                    >
                        we're currently scheduling your experience! as soon as your match is confirmed, you will get an email with all the info you need!
                    </p>
                </div>
                <div className="guidedone__privacy-container">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 'clamp(9px, 1.5vw, 15px)',
                            color: 'var(--color-orange)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0px 50px',
                            textAlign: 'center'
                        }}
                    >
                        <p>
                            any questions? last minute details you forgot to add? don't hesitate to email us at {' '}
                            <a 
                                href="mailto:admin@tourember.com"
                                style={{ textDecoration: 'underline' }}>
                                admin@tourember.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="guidedone__bottom-area-container">
                <img src={confetti1} />
                <img src={confetti2} />
            </div>
        </div>
    )
};

export default Booking_Confirmation_Page;