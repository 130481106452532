import React from 'react';
import './navbar.css';
import emberlogo from '../../../../assets/newemberlogo.png'
// import emberlogo from '../../../../assets/emberlogo.svg';
import { Link } from 'react-router-dom';

const Menu = () => (
  <>
    {/* <p><a href='#home'>home</a></p> */}
    <p><a href='#why'>why?</a></p>
    <p><a href='#process'>process</a></p>
    <p><a href='#guides'>guides</a></p>
    <p><a href='#pricing'>pricing</a></p>
  </>
)

const Navbar = () => {
  return (
    <div className='tour__navbar-entire-container'>
      <div className='tour__navbar'>
        <div className='tour__navbar-links_logo'>
          <img src={emberlogo} alt='logo' className='navbar__logo' />
        </div>
        <div className='tour__navbar-links_container'>
          <Menu />
        </div>
        <div className='tour__navbar-sign'>
          <Link to='/newsignin'><p className='navbar__signup-button'>sign in</p></Link>
          {/* <Link to='/newsignup'><button type='button' className='selected-button'>sign up</button></Link> */}
        </div>
      </div>
    </div>
  )
}

export default Navbar