import { React, useState } from 'react';
import './coming_soon_page.css';
import building from '../../assets/building.png';
import webdev from '../../assets/webdev.png';

const Coming_Soon_Page = () => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);

    const handleClick = () => {
        setIsButtonClicked(true);
        setTimeout(() => {
            setIsButtonClicked(false);
            setEmailSubmitted(true);
            setEmail(''); // Clear the email input
        }, 1000); // Reset the animation after 1 second
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(validateEmail(inputEmail) && inputEmail.trim().length > 0);
      };
    
      const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
      };

    return (
        <div className='coming__entire-container'>
            <div className='coming__left-side-container'>
                <div className='coming__text-container'>
                    <p className='coming__thankyou-message'>oops!</p>
                    <p className='coming__information-message'>we're almost done building. this page will be ready soon!</p>
                    {/* <p className='coming__beready-message'>your match is coming.</p> */}
                </div>
                {/* <div className='coming__email-container'>
                    <p className='coming__email-prompt'>think your buddy might be interested? share this site with them.</p>
                    <input className='coming__email-textbox' type='email' placeholder={emailSubmitted ? 'thanks, we sent it!' : "buddy's email"} value={email} onChange={handleEmailChange} />
                    <button className={`coming__email-button ${isButtonClicked ? 'coming__email-button--clicked' : ''}`} type='button' onClick={handleClick} disabled={!isEmailValid}>share</button>
                </div> */}
            </div>
            <div className='coming__right-side-container'>
                <img src={webdev} alt='building'></img>
            </div>
        </div>
    )
}

export default Coming_Soon_Page