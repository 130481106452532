import React, { useState } from 'react';
import Verticalphotoloop from './components/Verticalphotoloop';
import { auth } from '../../services/firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import './newsignin.css';

const Newsignin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSignIn = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                auth.signOut();
                setMessage('your email isn\'t verified!');
                return;
            }

            if (user.email.endsWith('@stanford.edu') || user.email.endsWith('@berkeley.edu')) {
                window.location.href = "/tourguideonboard";
            } else {
                window.location.href = '/newsearch';
            }
        } catch (error) {
            if (error.message.includes('auth/invalid-email')) {
                setMessage('invalid email!');
            }
            if (error.message.includes('auth/wrong-password')) {
                setMessage('wrong password!');
            }
            if (error.message.includes('auth/user-not-found')) {
                setMessage('user not found!');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (email) {
            try {
                await sendPasswordResetEmail(auth, email);
                setMessage('password reset link sent to ' + email + '!');
            } catch (error) {
                if (error.message.includes('auth/invalid-email')) {
                    setMessage('invalid email!');
                }
                if (error.message.includes('auth/user-not-found')) {
                    setMessage('user not found!');
                }
            }
        } else {
            setMessage('enter your email');
        }
    };

    return (
        <div className='newsignin__total-background'>
            <div className='center-container'>
                <div className='centered-box'>
                    <div className='newsignin__entire-container'>
                        <div className='newsignin__left-side-container'>
                            <Verticalphotoloop />
                        </div>
                        <div className='newsignin__right-side-container'>
                            <div className='newsignin__signup-redirect'>
                                <p>new to our platform?</p>
                                <a href='/newsignup' style={{ fontWeight: 700, color: 'var(--color-orange)', cursor: 'pointer' }}>sign up</a>
                            </div>
                            <div className='newsignin__signin-actual-container'>
                                <div className='newsignin__header-text-container'>
                                    <h1 style={{ fontFamily: 'var(--font-poppins)', fontSize: '40px'}}>welcome back!</h1>
                                    <p style={{ fontWeight: 500, fontSize: "clamp(10px, 2.5vw, 20px)" }}>great to see you</p>
                                </div>
                                <div className='newsignin__login-containers'>
                                    <input type='text' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <input type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className='newsignin__forgot-password-container'>
                                    <a onClick={handleForgotPassword} style={{ fontWeight: 700, color: 'var(--color-orange)', cursor: 'pointer', fontSize: 'clamp(8px, 0.75vw, 12px)' }}>forgot password?</a>
                                </div>
                                <div className='newsignin__signin-button-container'>
                                    {
                                        loading ?
                                        <div className="loadingspinner"></div> :
                                        <button
                                            onClick={handleSignIn}
                                            disabled={!email || !password}
                                            style={(!email || !password) ? { opacity: 0.5 } : {}}
                                        >
                                            sign in
                                        </button>
                                    }
                                </div>
                                {
                                    message && 
                                    <div className='newsignin__signin-messages'>
                                        <p>{message}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsignin