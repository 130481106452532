import React from "react";
import "./pricingcard.css";

const Pricingcard = ({ medium, price, subtext, featureText, featureList }) => {

    const handleClick = () => {
        window.location.href = "/newsignup";
    };

    return (
        <div className="pricingcard__entire-container">
            <div className="pricingcard__top-area-container">
                <p>{medium}</p>
                <div className="pricingcard__price-container">
                    <h1>{price}</h1>
                    <p style={{ color: "#464444" }}>{subtext}</p>
                </div>
                <button onClick={handleClick}>let's do it</button>
            </div>
            <div className="pricingcard__bottom-area-container">
                <p style={{ paddingBottom: "10px", fontWeight: 700 }}>{featureText}</p>
                <div className="pricingcard__feature-container">
                    <p>{featureList[0]}</p>
                    <p>{featureList[1]}</p>
                    <p>{featureList[2]}</p>
                </div>
            </div>
        </div>
    )
};

export default Pricingcard;
