import React from 'react';
import { FooterLink } from './components';
import { Link } from 'react-router-dom';
import './footer.css';

// started @12:18 PM PST on July 21, 2023
// finished @12:43 PM PST on July 21, 2023

const Footer = () => {
  return (
    <div className='footer__entire-container'>
      <div className='footer__columns-container'>
        <div className='footer__column1'>
          <p className='footer__column-heading'>company</p>
          <Link to="about"><FooterLink name="about" /></Link>
          {/* <Link to="https://www.linkedin.com/in/neelsn/"><FooterLink name="contact" /></Link> */}
          {/* <a href="mailto:your-email@example.com"><FooterLink name="contact" /></a> */}
          <a href="mailto:neelsn@stanford.edu" target="_blank" rel="noopener noreferrer"><FooterLink name="contact" /></a>
        </div>
        <div className='footer__column2'>
          <p className='footer__column-heading'>legal</p>
          <Link to="comingsoon"><FooterLink name="cookies policy" /></Link>
          <Link to="comingsoon"><FooterLink name="privacy policy" /></Link>
          <Link to="comingsoon"><FooterLink name="terms of service" /></Link>
        </div>
      </div>
      <div className='footer__small-ending-text'>
        <p>© 2023. All Rights Reserved.</p>
      </div>
    </div>
  )
}

export default Footer