import React, { useState } from 'react';
import starwhite from '../../../../assets/starwhite.png';
import staryellow from '../../../../assets/staryellow.png';
import './squareprofilecard.css';
import { ProfileTag } from '../../../search_page/components/guideprofile/components';

// started @ 4:22 PM PST on August 15, 2023

const Squareprofilecard = ({
                            profilecardImage,
                            profilecardTag1,
                            profilecardTag2,
                            profilecardTag3,
                            profilecardTag4,
                            profilecardPronouns,
                            profilecardName,
                            profilecardMajor,
                            profilecardYear,
                            profilecardHometown,
                            onClick,
                        }) => {

    const [starImage, setStarImage] = useState(starwhite);
    const handleStarClick = () => {
        setStarImage((prevImage) => (prevImage === starwhite ? staryellow : starwhite));
    };

    const [searchFavorite, setSearchFavorite] = useState(false);
    const handleFavoriteClick = () => {
        // setSearchFavorite((prevFavorite) => !prevFavorite);
        setSearchFavorite(!searchFavorite);
    };

    function getFontSizeClass(word) {
        const length = word.length;

        if (length <= 15) {
            return 'extraLargeFont';
        } else if (length <= 25) {
            return 'largeFont';
        } else if (length <= 29) {
            return 'mediumFont';
        } else {
            return 'smallFont';
        }
    };

    const majorFontSizeClass = getFontSizeClass(profilecardMajor);
    const hometownFontSizeClass = getFontSizeClass(profilecardHometown);
    const yearFontSizeClass = getFontSizeClass(profilecardYear);

    // function sayHello() {
    //     console.log("hello");
    // };

    // addEventListener('mouseover', sayHello);

    return (
        <div className={`squareprofilecard__entire-container ${searchFavorite ? 'favoriteActive' : ''}`}>
            <div className='squareprofilecard__top-area'>
                <div className='squareprofilecard__image-container'>
                    <img src={profilecardImage} />
                </div>
                <div className='squareprofilecard__top-area-right-side-container'>
                    <div className='squareprofilecard__tags-container'>
                        <div className='squareprofilecard__tags-container-row1'>
                            <ProfileTag tag={profilecardTag1} />
                            <ProfileTag tag={profilecardTag2} />
                        </div>
                        <div className='squareprofilecard__tags-container-row2'>
                            <ProfileTag tag={profilecardTag3} />
                            <ProfileTag tag={profilecardTag4} />
                        </div>
                    </div>
                    {/* <div style={{ display: "flex" }}>
                        <img src={starImage} style={{ width: "15%" }} alt='unchosen' />
                    </div> */}
                    {/* <button className='squareprofilecard__seemore-button'>see more</button> */}
                    {/* <img src={starImage} style={{ width: "20%" }} alt='unchosen' /> */}
                    <p style={{ fontFamily: "var(--font-poppins)", fontSize: "12px" }}>{profilecardPronouns}</p>
                </div>
            </div>
            <h1 style={{ fontFamily: "var(--font-poppins)", fontSize: "clamp(20px, 1.5vw, 25px)" }}>{profilecardName}</h1>
            <div className='squareprofilecard__bottom-area'>
                <div className='squareprofilecard__major-container'>
                    <p className='squareprofilecard__title-text'>major</p>
                    <p className={`squareprofilecard__info-text ${majorFontSizeClass}`}>{profilecardMajor}</p>
                </div>
                <div className='squareprofilecard__classyear-container'>
                    <p className='squareprofilecard__title-text'>year</p>
                    <p className={`squareprofilecard__info-text ${yearFontSizeClass}`}>{profilecardYear}</p>
                </div>
                <div className='squareprofilecard__hometown-container'>
                    <p className='squareprofilecard__title-text'>hometown</p>
                    <p className={`squareprofilecard__info-text ${hometownFontSizeClass}`}>{profilecardHometown}</p>
                </div>
            </div>
            <div className='squareprofile__bottom-most-area'>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={starImage} style={{ width: "15%" }} alt='unchosen' />
                </div> */}
                <button
                    // className='squareprofilecard__favorite-button'
                    className='squareprofilecard__favorite-button'
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                        // alert("Hello");
                    }}
                >
                    see more!
                </button>
            </div>
        </div>
    )
}

export default Squareprofilecard